import { FrontendTestCaseDialog, type FrontendTestCaseDialogProps } from "@hireroo/project/react/v2/widget";
import * as React from "react";

export type ProjectV3FrontendTestCaseDialogProps = Pick<FrontendTestCaseDialogProps, "items"> & {
  open: boolean;
  onClose: () => void;
};

const ProjectV3FrontendTestCaseDialog: React.FC<ProjectV3FrontendTestCaseDialogProps> = props => {
  const frontendTestCaseDialogProps: FrontendTestCaseDialogProps = {
    ...props,
    open: props.open,
    onClose: () => {
      props?.onClose?.();
    },
  };

  return <FrontendTestCaseDialog {...frontendTestCaseDialogProps} />;
};

ProjectV3FrontendTestCaseDialog.displayName = "ProjectV3FrontendTestCaseDialog";

export default ProjectV3FrontendTestCaseDialog;
