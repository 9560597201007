import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import * as React from "react";

import ReportDetailSummary, { ReportDetailSummaryProps } from "../../modules/ReportDetailSummary/ReportDetailSummary";
import ReportPageFooter, { ReportPageFooterProps } from "../../modules/ReportPageFooter/ReportPageFooter";

type EntityId = string;

type Report = {
  id: EntityId;
  smoothScrollTargetId: string;
  Content: React.ReactNode;
};

export type AssessmentReportProps = {
  summary: ReportDetailSummaryProps;
  Reports: Report[];
  selectedEntityId: string;
  footer: ReportPageFooterProps;
  targetElementId: string;
};

const AssessmentReport: React.FC<AssessmentReportProps> = props => {
  const ReportMap: Record<EntityId, React.ReactNode> = props.Reports.reduce((prev, current) => {
    return {
      ...prev,
      [current.id]: current.Content,
    } satisfies Record<EntityId, React.ReactNode>;
  }, {});
  return (
    <Box position="relative" sx={{ height: "100%" }}>
      <Box p={3}>
        <ReportDetailSummary {...props.summary} />

        <Divider light />

        <Box id={props.targetElementId}>{ReportMap[props.selectedEntityId]}</Box>
      </Box>
      <ReportPageFooter {...props.footer} />
    </Box>
  );
};

AssessmentReport.displayName = "AssessmentReport";

export default AssessmentReport;
