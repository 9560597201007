import type * as Interface from "@hireroo/evaluation-result-interface";
import Stack from "@mui/material/Stack";
import * as React from "react";

import ResultBlock from "./parts/ResultBlock/ResultBlock";

export type ResultBlocksProps = Interface.TestResult;

const ResultBlocks: React.FC<ResultBlocksProps> = props => {
  return (
    <Stack direction="column" spacing={2} p={2}>
      {props.blocks.map((block, index) => (
        <ResultBlock key={`block-${index}`} {...block} />
      ))}
    </Stack>
  );
};

ResultBlocks.displayName = "ResultBlocks";

export default ResultBlocks;
