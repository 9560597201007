import { useFailureMessageMap } from "@hireroo/app-definition/project";
import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import { useCurrentLanguage, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import { useHelpCenterNavigate } from "@hireroo/router/hooks";
import * as React from "react";

import * as PrivateHelper from "./privateHelper";

export type GenerateWorkspaceNotificationSectionPropsArgs = {
  kindOfReview: PrivateHelper.Kind;
  entityId: number;
  uniqueKey: ProjectTestReport.UniqueKey;
  testId: string;
};

type SingleMessageProps = Exclude<Widget.ProjectTestReportProps["workspaceNotificationSection"], undefined>["singleMessage"];

export const useGenerateWorkspaceNotificationSectionProps = (
  args: GenerateWorkspaceNotificationSectionPropsArgs,
): Widget.ProjectTestReportProps["workspaceNotificationSection"] => {
  const lang = useCurrentLanguage();
  const helpCenterNavigate = useHelpCenterNavigate(lang);
  const hooks = ProjectTestReport.useCreateProjectHooks(args.entityId);
  const submissionId = hooks.useCurrentSelectedSubmissionId();
  const submissionStatus = hooks.useCurrentSubmissionStatus();
  const failureReason = hooks.useCurrentSubmissionFailureReason();

  const { t } = useTranslation();

  const singleMessageProps = React.useMemo((): SingleMessageProps => {
    switch (submissionStatus) {
      case "ENQUEUED": {
        return {
          text: t("提出されたコードは採点中です。"),
        };
      }
      case "UNKNOWN": {
        return {
          text: t("提出されたコードは採点に失敗しました。お手数ですが運営側にお問い合わせください、"),
        };
      }
    }
  }, [submissionStatus, t]);

  const failureMessageMap = useFailureMessageMap();

  return {
    additionalMessages: submissionStatus === "FAILED" && failureReason ? [failureMessageMap[failureReason]] : [],
    helpLink: {
      onClick: () => {
        helpCenterNavigate("PROJECT_EVALUATION_ERROR", { _blank: true });
      },
    },
    singleMessage: singleMessageProps,
    url: PrivateHelper.getWorkspaceUrl({
      kind: args.kindOfReview,
      projectId: args.entityId,
      submissionId,
      testId: args.testId,
    }),
  };
};
