import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { type GenerateProjectV3DataScienceTestCaseDialogPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ProjectV3DataScienceTestCaseDialogContainerProps = GenerateProjectV3DataScienceTestCaseDialogPropsArgs;

const ProjectV3DataScienceTestCaseDialogContainer: React.FC<ProjectV3DataScienceTestCaseDialogContainerProps> = props => {
  const projectV3DataScienceTestCaseDialogProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ProjectV3DataScienceTestCaseDialog {...projectV3DataScienceTestCaseDialogProps} />
    </ErrorBoundary>
  );
};

ProjectV3DataScienceTestCaseDialogContainer.displayName = "ProjectV3DataScienceTestCaseDialogContainer";

export default withErrorBoundary(ProjectV3DataScienceTestCaseDialogContainer, {});
