import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import CheckIcon from "@mui/icons-material/Check";
import Box from "@mui/material/Box";
import MenuItem, { type MenuItemProps } from "@mui/material/MenuItem";
import Select, { type SelectProps } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type Item = {
  value: string;
  disabled: boolean;
  status: "PENDING" | "COMPLETED";
  tooltipText: string;
  displayText: string;
  onClick?: () => void;
};

const StyledBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

const IconBox = styled(Box)(() => ({
  width: 24,
  height: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "8px",
  svg: {
    width: 24,
    height: 24,
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.Other.FilledInputBG,
  marginRight: "10px",
  height: "30px",
  width: "120px",
  color: theme.palette.text.primary,
  fontSize: 16,
  ".MuiOutlinedInput-input": {
    padding: "3px 8px",
  },
  ".MuiSelect-nativeInput": {
    minHeight: 24,
    border: "none",
  },
  ".MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "[aria-hidden=true]": {
    display: "block",
  },
  "[aria-hidden=false]": {
    display: "none",
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    "&.Mui-selected": {
      backgroundColor: theme.palette["Secondary/Shades"].p16,
    },
  },
}));

const StyledTypography = styled(Typography)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  fontSize: 16,
}));

export type QuestionSwitcherProps = {
  className?: string;
  value: string;
  disabled?: boolean;
  items: Item[];
  onChange: (value: string) => void;
};

const QuestionSwitcher: React.FC<QuestionSwitcherProps> = props => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  const value = props.value;
  const selectProps: SelectProps = {
    value: value,
    color: "secondary",
    onChange: event => {
      const targetItem = props.items.find(item => item.value === event.target.value);
      if (targetItem) {
        props.onChange(targetItem.value);
      }
    },
    MenuProps: {
      MenuListProps: {
        sx: {
          "[aria-hidden=true]": {
            display: "block",
          },
          "[aria-hidden=false]": {
            display: "block",
          },
        },
      },
    },
  };

  return (
    <StyledBox>
      <StyledSelect {...selectProps}>
        {props.items.map(item => {
          const mark = item.status === "COMPLETED" ? <CheckIcon fontSize="small" color="success" /> : null;
          const menuItemProps: MenuItemProps = {
            value: item.value,
            children: (
              <Tooltip title={item.status === "COMPLETED" ? t("提出済") : ""}>
                <StyledTypography>
                  <IconBox as="span" aria-hidden={!!mark}>
                    {mark}
                  </IconBox>
                  {item.displayText}
                </StyledTypography>
              </Tooltip>
            ),
          };
          return <StyledMenuItem key={item.value} {...menuItemProps} />;
        })}
      </StyledSelect>
      <Typography fontSize="14px">/ {t2("AllQuestions", { num: props.items.length })}</Typography>
    </StyledBox>
  );
};

QuestionSwitcher.displayName = "QuestionSwitcher";

export default QuestionSwitcher;
