import { useEnabledProjectPlayback } from "@hireroo/app-helper/feature";
import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import * as Graphql from "@hireroo/graphql/client/urql";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

import ProjectContentsViewerV3Container from "./widget/ProjectContentsViewerV3/Container";
import ProjectContentsViewerV4InitialContainer from "./widget/ProjectContentsViewerV4/InitialContainer";

type Mode = "HISTORY" | "PLAYBACK" | "SUBMIT_RESULT";

export type GeneratePlaybackSectionProps = {
  entityId: number;
  uniqueKey: ProjectTestReport.UniqueKey;
  pause: boolean;
  canShowPasteAndTabStatistics: boolean;
};

export const useGeneratePlaybackSectionProps = (args: GeneratePlaybackSectionProps): Widget.ProjectTestReportProps["playbackSection"] => {
  const hooks = ProjectTestReport.useCreateProjectHooks(args.entityId);
  const projectMajorVersion = hooks.useProjectVersion();
  const questionId = hooks.useQuestionId();
  const enabledProjectPlayback = useEnabledProjectPlayback();
  // TODO: add v4 when it is ready
  const isPlaybackAvailable = projectMajorVersion === Graphql.ProjectVersion.V3 && enabledProjectPlayback;
  const [mode, setMode] = React.useState<Mode>(isPlaybackAvailable ? "SUBMIT_RESULT" : "HISTORY");
  const appealMessage = hooks.useAppealMessage();
  const submission = hooks.useCurrentSubmission();

  const ContentModeMap: Record<Mode, React.ReactNode> = {
    HISTORY: typeof questionId === "number" ? <ProjectContentsViewerV3Container projectId={args.entityId} questionId={questionId} /> : null,
    SUBMIT_RESULT:
      typeof questionId === "number" ? <ProjectContentsViewerV3Container projectId={args.entityId} questionId={questionId} /> : null,
    PLAYBACK: enabledProjectPlayback && submission && (
      // TODO: Temporary name. Change name when the scope is clear
      <ProjectContentsViewerV4InitialContainer projectId={args.entityId} canShowPasteAndTabStatistics={args.canShowPasteAndTabStatistics} />
    ),
  };

  const ContentViewerMap: Record<Graphql.ProjectVersion, React.ReactNode> = {
    v2: null,
    v3: ContentModeMap[mode],
    v4: ContentModeMap[mode],
  };
  const playbackModeSwitcher = React.useMemo((): Exclude<
    Widget.ProjectTestReportProps["playbackSection"],
    undefined
  >["playbackModeSwitcher"] => {
    if (isPlaybackAvailable) {
      return {
        onChangeMode: mode => {
          if (mode === "HISTORY" || mode === "PLAYBACK" || mode === "SUBMIT_RESULT") {
            setMode(mode);
          }
        },
        enableMode: ["SUBMIT_RESULT", "PLAYBACK"],
      };
    }
    return undefined;
  }, [isPlaybackAvailable]);

  return {
    playbackModeSwitcher: playbackModeSwitcher,

    ContentsViewer: ContentViewerMap[projectMajorVersion],
    appealMessageArea: appealMessage
      ? {
          body: appealMessage,
        }
      : undefined,
  };
};
