import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { ProjectBackendTestCaseSchemaV3 } from "@hireroo/validator";

export type GenerateProblemTextArgsForBackend = {
  command: ProjectBackendTestCaseSchemaV3.BackendCommandSchema;
  resultPerCommand?: ProjectBackendTestCaseSchemaV3.BackendResultPerCommand;
};

export const useGetTestResultDescriptionGeneratorForBackend = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  return ({ command, resultPerCommand }: GenerateProblemTextArgsForBackend): string => {
    if (!resultPerCommand) return t("テストケースの実行前です。");

    const problems: string[] = [];
    // For each command in the test case, check if id might be a problem
    if (resultPerCommand.status !== command.status) {
      problems.push(
        "- " +
          t2("BackendStatusWarningText", {
            status: command.status,
          }),
      );
    }

    if (resultPerCommand.latency > command.timeout) {
      problems.push(
        "- " +
          t2("BackendDeadlineExceededWarningText", {
            deadline: resultPerCommand.latency,
          }),
      );
    }

    if (resultPerCommand.expected !== "ANY") {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let x: any = {};
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let y: any = {};
      try {
        x = JSON.parse(resultPerCommand.output) ?? {};
      } catch (err) {
        // user value could be invalid json.
        // ignore an error as it's not critical
      }

      try {
        y = JSON.parse(resultPerCommand.expected) ?? {};
      } catch (err) {
        // expected value could be invalid json.
        // ignore an error as it's not critical
      }

      // Deep required equal
      for (const key of Object.keys(y)) {
        if (!x[key]) {
          const displayKey = Array.isArray(y) ? `\`${key}\`` + t("番目の要素") : `\`${key}\``;

          problems.push(
            "- " +
              t2("BackendMissingKeyWarningText", {
                key: displayKey,
              }),
          );
        } else {
          if (x[key] !== y[key]) {
            const displayKey = Array.isArray(y) ? `\`${key}\`` + t("番目の要素") : `\`${key}\``;

            problems.push(
              "- " +
                t2("BackendValueDifferWarningText", {
                  key: displayKey,
                  expected: `\`${JSON.stringify(y[key])}\``,
                }),
            );
          }
        }
      }
    }

    if (problems.length === 0) {
      return t("実行結果に問題がありました。実装されているコードを確認してください。");
    }
    return t("以下の問題が発生しています。コードを確認してください") + ":\n" + problems.join("\n");
  };
};
