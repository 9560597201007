import { useTranslation } from "@hireroo/i18n";
import Logo from "@hireroo/ui-assets/images/Logo/Logo.png";
import AppBar from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import * as React from "react";

import QuestionSwitcher, { QuestionSwitcherProps } from "../../modules/QuestionSwitcher/QuestionSwitcher";
import BaseDialog, { BaseDialogProps } from "../../primitive/BaseDialog/BaseDialog";
import Menu, { MenuProps } from "./parts/Menu/Menu";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  border: "none",
  borderBottom: `solid 1px ${theme.palette["Secondary/Shades"].p16}`,
  backgroundColor: theme.palette.HeaderBG,
}));

export type ScreeningTestHeaderProps = {
  endTestDialog?: Pick<BaseDialogProps, "open" | "yesButton" | "noButton">;
  questionSwitcher?: QuestionSwitcherProps;
  Timelimit: React.ReactNode;
  menu: MenuProps;
};

const ScreeningTestHeader: React.FC<ScreeningTestHeaderProps> = props => {
  const { t } = useTranslation();
  const baseDialogProps: BaseDialogProps | undefined = props.endTestDialog
    ? {
        ...props.endTestDialog,
        title: t("テストを終了します。"),
        yesButton: {
          ...props.endTestDialog?.yesButton,
          disabled: false,
          children: t("はい"),
        },
        noButton: {
          ...props.endTestDialog?.noButton,
          disabled: false,
          children: t("いいえ"),
        },
      }
    : undefined;

  return (
    <StyledAppBar variant="outlined" elevation={0} sx={{ width: "100%" }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" py={0.5} px={1.5} width="100%">
        <Stack direction="row" spacing={2} alignItems="center">
          <img src={Logo} width={100} alt="hireroo" />
        </Stack>
        <Stack spacing={1.5} direction="row">
          {props.Timelimit}
          {props.questionSwitcher && <QuestionSwitcher {...props.questionSwitcher} />}
        </Stack>
        <Menu {...props.menu} />
      </Stack>
      {baseDialogProps && (
        <BaseDialog {...baseDialogProps}>
          {t("テストを終了すると、提出前の問題はすべて未提出として扱われますがよろしいでしょうか。")}
        </BaseDialog>
      )}
    </StyledAppBar>
  );
};

ScreeningTestHeader.displayName = "ScreeningTestHeader";

export default ScreeningTestHeader;
