import type { Widget } from "@hireroo/presentation";

export type GenerateProjectTestCaseRunnerPropsArgs = {};

export const useGenerateProps = (_args: GenerateProjectTestCaseRunnerPropsArgs): Widget.ProjectTestCaseRunnerProps => {
  return {
    open: true,
    onClose: () => {
      //,
    },
    description: "",
    runAll: {
      onClick: () => {
        //
      },
    },
    items: [],
  };
};
