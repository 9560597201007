import { useTranslation } from "@hireroo/i18n";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import type { ButtonProps } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import ProjectConsole, { ProjectConsoleProps } from "./parts/ProjectConsole";

type LoadingButtonProps = ButtonProps & { loading: boolean };

type RunningButtonExtraProps = {
  meta:
    | {
        kind: "READY";
      }
    | {
        kind: "RUNNING";
        count: number;
      };
};

export type ProjectTerminalV3Props = {
  runTestCaseButton: Pick<LoadingButtonProps, "onClick" | "disabled" | "loading"> & RunningButtonExtraProps;
  submitButton: Pick<LoadingButtonProps, "onClick" | "disabled" | "loading">;
  console?: Omit<ProjectConsoleProps, "terminalKey">;
};

const StyledBox = styled(Box)`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TabBarBox = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledButton = styled(LoadingButton)(({ theme }) => ({
  wordBreak: "keep-all",
  height: "32px",
  "&:hover": {
    borderColor: theme.palette.secondary.main,
  },
}));

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: "180%",
    top: 12,
    padding: "0 4px",
  },
}));

const ProjectTerminalV3: React.FC<ProjectTerminalV3Props> = props => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { meta, ...runTestCaseButton } = props.runTestCaseButton;

  const runTestCaseButtonProps: LoadingButtonProps = {
    ...runTestCaseButton,
    id: "project-run-button",
    variant: "text",
    color: "secondary",
    size: "small",
    ...(meta.kind === "READY"
      ? {
          children: t("実行"),
          startIcon: <PlayArrowRoundedIcon />,
        }
      : {
          children: t("実行"),
          startIcon: null,
        }),
  };
  const submitButtonProps: LoadingButtonProps = {
    ...props.submitButton,
    id: "project-submit-button",
    variant: "contained",
    color: "secondary",
    size: "small",
    children: t("コード提出"),
    startIcon: <SendIcon />,
  };
  return (
    <StyledBox>
      <AppBar elevation={0} position="static" color="default" sx={{ backgroundColor: theme.palette["Secondary/Shades"].p12, height: 36 }}>
        <TabBarBox bgcolor={theme.palette["Secondary/Shades"].p12}>
          <Typography px={1.5} py={1} display="flex" alignItems="center" variant="body2" sx={{ wordBreak: "keep-all" }}>
            {props.console && t("ターミナル")}
          </Typography>
          <Stack direction="row" spacing={2} alignItems="center" px={2}>
            {meta.kind === "READY" && <StyledButton key="run" {...runTestCaseButtonProps} />}
            {meta.kind === "RUNNING" && (
              <StyledButton key="run" {...runTestCaseButtonProps}>
                <StyledBadge badgeContent={meta.count} color="secondary">
                  {runTestCaseButtonProps.children}
                </StyledBadge>
              </StyledButton>
            )}

            <StyledButton key="submit" {...submitButtonProps} />
          </Stack>
        </TabBarBox>
      </AppBar>
      {props.console && <ProjectConsole {...props.console} />}
    </StyledBox>
  );
};

ProjectTerminalV3.displayName = "ProjectTerminal";

export default ProjectTerminalV3;
