import { Payment } from "@hireroo/app-store/essential/talent";
import { SystemDesignTestReport } from "@hireroo/app-store/view-domain/SystemDesignTestReport";
import { formatSeconds } from "@hireroo/formatter/time";
import * as Graphql from "@hireroo/graphql/client/urql";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

import { useGenerateSystemDesignScoringItemPropsFactory } from "../../../../props-factory/v2/view-domain/SystemDesignTestReport/privateHelper";
import { useGenerateEntityScoreBoardProps } from "../../../../props-factory/v2/view-domain/SystemDesignTestReport/useGenerateEntityScoreBoardProps";
import { useGenerateHeaderProps } from "../../../../props-factory/v2/view-domain/SystemDesignTestReport/useGenerateHeaderProps";
import { useGenerateQuestionDetailProps } from "../../../../props-factory/v2/view-domain/SystemDesignTestReport/useGenerateQuestionDetailProps";
import SystemDesignPlaybackContainer from "../../../../props-factory/v2/view-domain/SystemDesignTestReport/widget/SystemDesignPlayback/Container";
import TotalScoreRankVisualizerInitialContainer from "../../shared/TotalScoreRankVisualizer/InitialContainer";

export type GenerateSystemDesignTestReportPropsArgs = {
  featureKind: "test" | "exam";
  entityId: number;
  companyId: number;
  uniqueKey: SystemDesignTestReport.UniqueKey;
  reportSettings: Graphql.AssessmentReportSettings | null;
};

export const useGenerateProps = (args: GenerateSystemDesignTestReportPropsArgs): Widget.SystemDesignTestReportProps => {
  const lang = useLanguageCode();
  const { t } = useTranslation();
  const hooks = SystemDesignTestReport.useCreateSystemDesignHooks(args.entityId);
  const currentSubmission = hooks.useCurrentSubmission();
  const rankEvaluation = hooks.useRankEvaluation();
  const aggregatedScoringItemMap = hooks.useAggregatedScoringItemMap();
  const numOfRun = hooks.useNumOfRun();
  const numOfUsedHint = hooks.useNumOfUsedHint();
  const statistics = hooks.useStatistics();
  const appealMessage = hooks.useAppealMessage();
  const isAvailableFeature = Payment.useIsAvailableFeature();
  const convert = useGenerateSystemDesignScoringItemPropsFactory({
    entityId: args.entityId,
    showAnswer: args.reportSettings?.showAnswer ?? false,
  });
  const [mode, setMode] = React.useState<"PLAYBACK" | "SUBMIT_RESULT">("SUBMIT_RESULT");
  const header = useGenerateHeaderProps({ entityId: args.entityId });
  const aggScoringItemMap = hooks.useCalculatedScoringItemMap();
  const scoreBoard = useGenerateEntityScoreBoardProps({
    entityId: args.entityId,
    showingTargets: ["SCORE", "RANK"],
  });
  const questionDetail = useGenerateQuestionDetailProps({
    entityId: args.entityId,
    showAnswer: args.reportSettings?.showAnswer ?? false,
    showArchivedMark: false,
  });

  return {
    header: header,
    scoreBoard: scoreBoard,
    StatisticsContent: isAvailableFeature(`${args.featureKind}.statics.read`) && args.reportSettings?.showRelativeEvaluation && (
      <TotalScoreRankVisualizerInitialContainer
        uniqueKey={args.uniqueKey}
        score={currentSubmission?.totalScore ?? 0}
        rankEvaluation={rankEvaluation}
        enableRank
        readOnly
      />
    ),
    playbackSection: {
      playbackModeSwitcher:
        isAvailableFeature(`${args.featureKind}.playback.read`) && args.reportSettings?.showPlayback
          ? {
              enableMode: ["SUBMIT_RESULT", "PLAYBACK"],
              onChangeMode: mode => {
                if (mode === "PLAYBACK" || mode === "SUBMIT_RESULT") {
                  setMode(mode);
                }
              },
            }
          : undefined,
      ContentsViewer: <SystemDesignPlaybackContainer entityId={args.entityId} mode={mode} />,
      appealMessageArea: !!appealMessage && {
        body: appealMessage,
      },
    },
    answerDetailSection: {
      totalElapsedTime: {
        value: formatSeconds(currentSubmission?.totalElapsedTimeSeconds ?? 0, lang),
        icon: {
          key: (currentSubmission?.totalElapsedTimeSeconds ?? 0) <= statistics.elapsedAvgTimeSeconds ? "CHECK" : "WARNING",
          title: `${t("平均解答時間")} ${formatSeconds(statistics.elapsedAvgTimeSeconds, lang)}`,
        },
      },
      runNum: {
        value: `${numOfRun} ${t("回")}`,
        icon: {
          key: numOfRun <= statistics.numOfRunAvg ? "CHECK" : "WARNING",
          title: `${t("平均実行回数")} ${statistics.numOfRunAvg}`,
        },
      },
      usedHint: {
        value: `${numOfUsedHint} ${t("個")}`,
        icon: {
          key: numOfUsedHint <= statistics.numOfHintAvg ? "CHECK" : "WARNING",
          title: `${t("平均使用ヒント数")} ${statistics.numOfHintAvg}`,
        },
      },
      canShowTooltip: isAvailableFeature("test.statics.read"),
    },
    editableScoreSection: {
      onSubmit: () => {
        /** not necessary in talent */
      },
      formActionButtonGroup: undefined,
      defaultValues: {
        scoringItems: aggregatedScoringItemMap.all.items.map(item => {
          return {
            id: item.systemDesignScoringItemId,
            isPassed: item.isPassed,
          };
        }),
      },
      availability: {
        scrollTargetId: SystemDesignTestReport.TargetElementIdMap.AVAILABILITY_SECTION,
        titleWithScoreBar: {
          title: t("可用性"),
          score: aggScoringItemMap.availability?.score ?? 0,
          color: "secondary",
        },
        items: aggregatedScoringItemMap.availability.items.map(convert),
      },
      scalability: {
        scrollTargetId: SystemDesignTestReport.TargetElementIdMap.SCALABILITY_SECTION,
        titleWithScoreBar: {
          title: t("スケーラビリティ"),
          score: aggScoringItemMap.availability?.score ?? 0,
          color: "info",
        },
        items: aggregatedScoringItemMap.scalability.items.map(convert),
      },
      consistency: {
        scrollTargetId: SystemDesignTestReport.TargetElementIdMap.CONSISTENCY_SECTION,
        titleWithScoreBar: {
          title: t("一貫性"),
          score: aggScoringItemMap.availability?.score ?? 0,
          color: "warning",
        },
        items: aggregatedScoringItemMap.consistency.items.map(convert),
      },
    },
    /**
     * talent will always hide open button
     */
    questionDetail: { ...questionDetail, openButton: undefined },
  };
};
