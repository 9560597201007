import type { Widget } from "@hireroo/presentation";

export type GenerateTerminalPropsArgs = {
  ready: boolean;
  endpoint: string;
  runTestCaseButton: Omit<Widget.ProjectCodingEditorV3Props["terminal"]["runTestCaseButton"], "meta">;
  submitButton: Widget.ProjectCodingEditorV3Props["terminal"]["submitButton"];
  showConsole: boolean;
};

export const useGenerateTerminalProps = (args: GenerateTerminalPropsArgs): Widget.ProjectCodingEditorV3Props["terminal"] => {
  const { ready, endpoint } = args;
  return {
    console: args.showConsole
      ? {
          endpoint: ready ? endpoint : undefined,
          loading: !ready,
        }
      : undefined,
    runTestCaseButton: {
      ...args.runTestCaseButton,
      // TODO PRで詳細な分岐を実装する
      meta: {
        kind: "READY",
      },
    },
    submitButton: args.submitButton,
  };
};
