import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { useGenerateProps } from "./useGenerateProps";

export type ProjectTestCaseRunnerContainerProps = {};

const ProjectTestCaseRunnerContainer: React.FC<ProjectTestCaseRunnerContainerProps> = () => {
  const projectTestCaseRunnerProps = useGenerateProps({});
  return (
    <ErrorBoundary>
      <Widget.ProjectTestCaseRunner {...projectTestCaseRunnerProps} />
    </ErrorBoundary>
  );
};

ProjectTestCaseRunnerContainer.displayName = "ProjectTestCaseRunnerContainer";

export default withErrorBoundary(ProjectTestCaseRunnerContainer, {});
