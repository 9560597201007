import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { type GenerateProjectV3FrontendTestCaseDialogPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ProjectV3FrontendTestCaseDialogContainerProps = GenerateProjectV3FrontendTestCaseDialogPropsArgs;

const ProjectV3FrontendTestCaseDialogContainer: React.FC<ProjectV3FrontendTestCaseDialogContainerProps> = props => {
  const projectV3FrontendTestCaseDialogProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ProjectV3FrontendTestCaseDialog {...projectV3FrontendTestCaseDialogProps} />
    </ErrorBoundary>
  );
};

ProjectV3FrontendTestCaseDialogContainer.displayName = "ProjectV3FrontendTestCaseDialogContainer";

export default withErrorBoundary(ProjectV3FrontendTestCaseDialogContainer, {});
