import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import { ProjectFrontendTestCaseSchemaV3 } from "@hireroo/validator";

export type GenerateProblemTextArgsForFrontend = {
  externalIp: string;
  testCase: ProjectFrontendTestCaseSchemaV3.FrontendCorrectnessTestCaseSchema;
  testResult?: ProjectFrontendTestCaseSchemaV3.FrontendCorrectnessTestCaseResultSchema;
};

export const useGetTestResultDescriptionGeneratorForFrontend = () => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();
  return ({ testCase, testResult, externalIp }: GenerateProblemTextArgsForFrontend): string => {
    if (!testResult) return t("テストケースの実行前です。");

    const problems: string[] = [];
    // For each command in the test case, check if id might be a problem
    for (let i = 0; i < testCase.test_case_commands.length; i++) {
      if (testResult.is_passed) continue;
      if (testCase.test_case_commands[i].action === "LOAD") {
        // If loading is the problem, let the user know server should be started
        problems.push(
          "- " +
            t2("FrontendLoadWarningText", {
              applicationUrl: `http://${externalIp}:8000${testCase.test_case_commands[i].body}`,
            }),
        );
      } else if (testCase.test_case_commands[i].action === "ERR") {
        // If error is the problem, let the user know error was expected
        problems.push("- " + t2("FrontendErrorWarningText"));
      } else {
        // If ID is the problem, let the user know, otherwise ignore it
        problems.push(
          "- " +
            t2("FrontendIDWarningText", {
              componentID: `\`${testCase.test_case_commands[i].id}\``,
            }),
        );
      }
    }

    if (problems.length === 0) {
      return t("実行結果に問題がありました。実装されているコードを確認してください。");
    }

    return t("以下の問題が発生しています。コードを確認してください") + ":\n" + problems.join("\n");
  };
};
