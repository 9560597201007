import { useTranslation, useTranslationWithVariable } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";

import DialogWithCloseIcon, { DialogWithCloseIconProps } from "../../primitive/DialogWithCloseIcon/DialogWithCloseIcon";
import TestCaseItem, { TestCaseItemProps } from "./parts/TestCaseItem/TestCaseItem";

const ActionBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  minHeight: "30px",
}));

export type ProjectTestCaseRunnerProps = {
  open: boolean;
  description: string;
  runAll: Pick<ButtonProps, "onClick" | "disabled">;
  items: TestCaseItemProps[];
  onClose: () => void;
};

const AccordionWrapper = styled(Box)(() => ({
  "& > *:first-of-type": {
    borderTop: "none",
  },
}));

const ProjectTestCaseRunner: React.FC<ProjectTestCaseRunnerProps> = props => {
  const items = props.items;
  const { t } = useTranslation();
  const { t: t2 } = useTranslationWithVariable();

  const runAll: ButtonProps = {
    ...props.runAll,
    children: t("すべて実行"),
    color: "secondary",
  };

  const runningCount = items.reduce<number>((total, item) => {
    if (item.status === "PENDING") {
      return total + 1;
    }
    return total;
  }, 0);

  const dialogProps: DialogWithCloseIconProps = {
    title: runningCount > 0 ? t2("RunningTestCaseCount", { count: runningCount }) : t("テストケースの実行"),
    open: props.open,
    onClose: () => {
      props.onClose?.();
    },
  };
  return (
    <DialogWithCloseIcon {...dialogProps}>
      <Box>
        <Typography variant="body2" color="text.secondary">
          {props.description}
        </Typography>
        <ActionBox>
          <Button {...runAll} />
        </ActionBox>
        <AccordionWrapper>
          {items.map((item, index) => {
            return <TestCaseItem key={index.toString()} {...item} />;
          })}
        </AccordionWrapper>
      </Box>
    </DialogWithCloseIcon>
  );
};

ProjectTestCaseRunner.displayName = "ProjectTestCaseRunner";

export default ProjectTestCaseRunner;
