import * as z from "zod";

export const ProjectMetrics = z.object({
  version: z.string(),
  metrics: z
    .object({
      title_ja: z.string(),
      title_en: z.string(),
      score: z.number(),
    })
    .array(),
});

export type ProjectMetrics = z.infer<typeof ProjectMetrics>;
