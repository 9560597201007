import { DataScienceTestCaseDialog, type DataScienceTestCaseDialogProps } from "@hireroo/project/react/v2/widget";
import * as React from "react";

export type ProjectV3DataScienceTestCaseDialogProps = Pick<DataScienceTestCaseDialogProps, "items"> & {
  open: boolean;
  onClose: () => void;
};

const ProjectV3DataScienceTestCaseDialog: React.FC<ProjectV3DataScienceTestCaseDialogProps> = props => {
  const dataScienceTestCaseDialogProps: DataScienceTestCaseDialogProps = {
    ...props,
    open: props.open,
    onClose: () => {
      props?.onClose?.();
    },
  };
  return <DataScienceTestCaseDialog {...dataScienceTestCaseDialogProps} />;
};

ProjectV3DataScienceTestCaseDialog.displayName = "ProjectV3DataScienceTestCaseDialog";

export default ProjectV3DataScienceTestCaseDialog;
