import { useFetchIpAddress } from "@hireroo/app-helper/hooks";
import { generateEntityKey } from "@hireroo/app-helper/normalizer";
import { useTitle } from "@hireroo/app-helper/react-use";
import { Auth } from "@hireroo/app-store/essential/candidate";
import { InterviewsIdStore } from "@hireroo/app-store/page/c/interviews_id";
import { InterviewNavigation } from "@hireroo/app-store/widget/c/InterviewNavigation";
import { ChallengeCodingEditor } from "@hireroo/app-store/widget/shared/ChallengeCodingEditor";
import { ProjectCodingEditorV3 } from "@hireroo/app-store/widget/shared/ProjectCodingEditorV3";
import { QuizCodingEditor } from "@hireroo/app-store/widget/shared/QuizCodingEditor";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { SystemDesignCodingEditor } from "@hireroo/app-store/widget/shared/SystemDesignCodingEditor";
import * as Graphql from "@hireroo/graphql/client/graphql-request";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useTranslation } from "@hireroo/i18n";
import type { Pages } from "@hireroo/presentation";
import * as React from "react";

import CandidateFooterContainer from "../../../../widget/v2/c/CandidateFooter/Container";
import ScreeningTestHeaderContainer, { ScreeningTestHeaderContainerProps } from "../../../../widget/v2/c/ScreeningTestHeader/Container";
import ChallengeCodingEditorInitialContainer, {
  ChallengeCodingEditorInitialContainerProps,
} from "../../../../widget/v2/shared/ChallengeCodingEditor/InitializeContainer";
import ProjectCodingEditorContainerV3, {
  ProjectCodingEditorInitialContainerV3Props,
} from "../../../../widget/v2/shared/ProjectCodingEditorV3/InitialContainer";
import QuizCodingEditorInitialContainer, {
  QuizCodingEditorInitialContainerProps,
} from "../../../../widget/v2/shared/QuizCodingEditor/InitialContainer";
import ScreeningTestTutorialContainer from "../../../../widget/v2/shared/ScreeningTestTutorial/Container";
import SnackbarContainer from "../../../../widget/v2/shared/Snackbar/Container";
import SystemDesignCodingEditorInitialContainer, {
  SystemDesignCodingEditorInitialContainerProps,
} from "../../../../widget/v2/shared/SystemDesignCodingEditor/InitialContainer";
import TestAnnouncementContainer from "../../../../widget/v2/shared/TestAnnouncement/Container";

export type GenerateScreeningTestPropsArgs = {
  spotId: string;
};

type EntityComponentMap = Record<string, React.ReactNode>;

export const useGenerateProps = (args: GenerateScreeningTestPropsArgs): Pages.ScreeningTestProps => {
  const { t } = useTranslation();
  const uid = Auth.useCurrentUid();
  const messageForCandidate = InterviewsIdStore.useSpotMessageForCandidate();
  const spotStatus = InterviewsIdStore.useSpotStatus();
  const candidateName = InterviewsIdStore.useCandidateName();
  const entities = InterviewsIdStore.useEntities();
  const endInterviewStatus = InterviewsIdStore.useEndInterviewStatus();
  const endInterviewLoadingStatus = InterviewsIdStore.useEndInterviewLoadingStatus();
  const enabledWebSearch = InterviewsIdStore.useEnabledWebSearch();
  const enabledChatGPT = InterviewsIdStore.useEnabledChatGPT();
  const currentEntityKey = InterviewNavigation.useCurrentSelectedEntityKey();
  const loadingStatus = InterviewNavigation.useLoadingStatus();
  const client = getGraphqlClient();
  const allQuestionsHaveBeenSubmitted = InterviewNavigation.useAllQuestionsHaveBeenSubmitted();
  const ipInfo = useFetchIpAddress();
  const metaTitle = React.useMemo((): string => {
    switch (spotStatus) {
      case "CREATED":
      case "ACCEPTED":
      case "STARTED":
      case "COMPLETED":
      case "FINALIZED":
        return t("テスト");
      default:
        return t("テスト終了");
    }
  }, [spotStatus, t]);

  useTitle(metaTitle);

  const interviewNavigationContainerProps: Omit<ScreeningTestHeaderContainerProps, "showingMode"> = {
    onEndInterview: () => {
      InterviewsIdStore.updateEndInterviewLoadingStatus("PENDING");
      client
        .EndSpot({
          spotId: args.spotId,
        })
        .then(res => {
          InterviewsIdStore.setSpot(res.endSpot);
        })
        .catch(() => {
          Snackbar.notify({
            severity: "error",
            message: t("テストの終了に失敗しました。お手数ですがヘルプセンターより運営にお問い合わせください。"),
          });
        })
        .finally(() => {
          InterviewsIdStore.updateEndInterviewLoadingStatus("READY");
        });
    },
    willBlockEndInterview: !allQuestionsHaveBeenSubmitted,
    disabledFinishButton: endInterviewLoadingStatus === "PENDING",
    messageForCandidate: messageForCandidate,
  };

  const ContentMap: EntityComponentMap = React.useMemo<EntityComponentMap>(() => {
    return entities.reduce<EntityComponentMap>((all, entity) => {
      const key = generateEntityKey(entity);
      switch (entity.__typename) {
        case "ChallengeEntity": {
          if (ipInfo?.ipAddress && ipInfo?.geolocation) {
            ChallengeCodingEditor.setIpAddress(ipInfo.ipAddress);
            ChallengeCodingEditor.setGeolocation(ipInfo.geolocation);
          }
          const challengeCodingEditor: ChallengeCodingEditorInitialContainerProps = {
            uid: uid ?? "",
            entityId: entity.challengeEntityId,
            editorKind: "CANDIDATE",
            interviewKind: "INTERVIEW",
            displayName: candidateName,
            enableBrowserEventDetector: true,
            enabledWebSearch: enabledWebSearch,
            enabledChatGPT: enabledChatGPT,
          };
          return {
            ...all,
            [key]: <ChallengeCodingEditorInitialContainer key={key} {...challengeCodingEditor} />,
          };
        }
        case "ProjectEntity": {
          switch (entity.question?.projectVersion) {
            case Graphql.ProjectVersion.V2:
            case Graphql.ProjectVersion.V3:
            case Graphql.ProjectVersion.V4: {
              if (ipInfo?.ipAddress && ipInfo?.geolocation) {
                ProjectCodingEditorV3.setIpAddress(ipInfo.ipAddress);
                ProjectCodingEditorV3.setGeolocation(ipInfo.geolocation);
              }
              const projectCodingEditorInitialContainerProps: ProjectCodingEditorInitialContainerV3Props = {
                mode: "DEVELOPMENT",
                uid: uid ?? "",
                userName: candidateName,
                entityId: entity.projectEntityId,
                endpointId: entity.projectEntityId,
                questionId: entity.question?.questionId ?? 0,
                questionVersion: entity.question?.version ?? "v2.0.0",
                submissionId: 0,
                isCandidate: true,
                enableBrowserEventDetector: true,
                isV4: entity.question?.projectVersion === Graphql.ProjectVersion.V4,
              };
              return { ...all, [key]: <ProjectCodingEditorContainerV3 key={key} {...projectCodingEditorInitialContainerProps} /> };
            }
            case undefined: {
              throw new Error(`Not found entity question`);
            }
            default:
              throw new Error(`Unsupported Project Version: ${entity.question?.projectVersion}`);
          }
        }
        case "QuizEntity": {
          if (ipInfo?.ipAddress && ipInfo?.geolocation) {
            QuizCodingEditor.setIpAddress(ipInfo.ipAddress);
            QuizCodingEditor.setGeolocation(ipInfo.geolocation);
          }
          const quizCodingEditorInitialContainerProps: QuizCodingEditorInitialContainerProps = {
            uid: uid ?? "",
            entityId: entity.quizEntityId,
            packageId: entity.pb_package?.packageId ?? 0,
            firstQuestionId: entity.pb_package?.questions.at(0)?.id ?? 0,
            interviewKind: "INTERVIEW",
            enableBrowserEventDetector: true,
          };
          return {
            ...all,
            [key]: <QuizCodingEditorInitialContainer {...quizCodingEditorInitialContainerProps} />,
          };
        }
        case "SystemDesignEntity": {
          if (ipInfo?.ipAddress && ipInfo?.geolocation) {
            SystemDesignCodingEditor.setIpAddress(ipInfo.ipAddress);
            SystemDesignCodingEditor.setGeolocation(ipInfo.geolocation);
          }
          const systemDesignCodingEditorInitialContainerProps: SystemDesignCodingEditorInitialContainerProps = {
            operator: {
              kind: "CANDIDATE",
              displayName: candidateName,
            },
            interviewKind: "INTERVIEW",
            showingOptions: [],
            uid: uid ?? "",
            entityId: entity.systemDesignEntityId,
            enableBrowserEventDetector: true,
          };
          return {
            ...all,
            [key]: <SystemDesignCodingEditorInitialContainer key={key} {...systemDesignCodingEditorInitialContainerProps} />,
          };
        }
        default: {
          return all;
        }
      }
    }, {});
  }, [entities, ipInfo, uid, candidateName, enabledWebSearch, enabledChatGPT]);

  if (endInterviewStatus) {
    return {
      layout: {
        loading: loadingStatus === "LOADING",
        NotificationBanner: null,
        Header: <ScreeningTestHeaderContainer {...interviewNavigationContainerProps} showingMode="READONLY" />,
        Footer: <CandidateFooterContainer />,
        Snackbar: <SnackbarContainer />,
        Tutorial: null,
      },
      Content: <TestAnnouncementContainer kind={endInterviewStatus} />,
    };
  }

  switch (spotStatus) {
    case "STARTED": {
      return {
        layout: {
          loading: loadingStatus === "LOADING",
          NotificationBanner: null,
          Header: <ScreeningTestHeaderContainer {...interviewNavigationContainerProps} showingMode="RUNNING" />,
          Footer: <CandidateFooterContainer />,
          Snackbar: <SnackbarContainer />,
          Tutorial: <ScreeningTestTutorialContainer />,
        },
        Content: currentEntityKey ? ContentMap[currentEntityKey] : undefined,
      };
    }
    case "COMPLETED":
    case "FINALIZED": {
      return {
        layout: {
          loading: loadingStatus === "LOADING",
          NotificationBanner: null,
          Header: <ScreeningTestHeaderContainer {...interviewNavigationContainerProps} showingMode="READONLY" />,
          Footer: <CandidateFooterContainer />,
          Snackbar: <SnackbarContainer />,
          Tutorial: null,
        },
        Content: <TestAnnouncementContainer kind="FINISH_TEST" />,
      };
    }
    default:
      return {
        layout: {
          loading: loadingStatus === "LOADING",
          NotificationBanner: null,
          Header: <ScreeningTestHeaderContainer {...interviewNavigationContainerProps} showingMode="READONLY" />,
          Footer: <CandidateFooterContainer />,
          Snackbar: <SnackbarContainer />,
          Tutorial: <ScreeningTestTutorialContainer />,
        },
        Content: <TestAnnouncementContainer kind="TEST_HAS_ALREADY_BEEN_COMPLETED" />,
      };
  }
};
