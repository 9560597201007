import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { type GenerateProjectV3BackendTestCaseDialogPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ProjectV3BackendTestCaseDialogContainerProps = GenerateProjectV3BackendTestCaseDialogPropsArgs;

const ProjectV3BackendTestCaseDialogContainer: React.FC<ProjectV3BackendTestCaseDialogContainerProps> = props => {
  const projectV3BackendTestCaseDialogProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ProjectV3BackendTestCaseDialog {...projectV3BackendTestCaseDialogProps} />
    </ErrorBoundary>
  );
};

ProjectV3BackendTestCaseDialogContainer.displayName = "ProjectV3BackendTestCaseDialogContainer";

export default withErrorBoundary(ProjectV3BackendTestCaseDialogContainer, {});
