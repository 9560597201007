import { parseFlowChartSnapshot } from "@hireroo/system-design/helpers/flowChart";
import { SystemDesignTestReport } from "@hireroo/validator";
import * as React from "react";
import { useSnapshot } from "valtio";

import * as Def from "./definition";
import { state } from "./State";
import type * as Types from "./types";

const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (entityId: number) => {
  const snapshot = useSnapshotState();
  return snapshot.systemDesigns.has(entityId);
};

export const useCreateSystemDesignHooks = (entityId: Types.SystemDesignEntityId) => {
  const snapshot = useSnapshotState();
  const entityState = snapshot.systemDesigns.get(entityId);
  const validator = SystemDesignTestReport.useSystemDesignScoringItems();

  if (!entityState) {
    throw new Error(`Not found ${entityId}`);
  }

  const { entity, currentSelectedSubmissionId } = entityState;

  const useSystemDesign = () => {
    return entity;
  };

  const useQuestion = () => {
    if (!entity.question) {
      throw new Error("Not found Question");
    }
    return entity.question;
  };

  const useQuestionId = () => {
    const question = useQuestion();
    return question.questionId;
  };

  const useSystemDesignId = () => {
    return entity.systemDesignEntityId;
  };

  const useScoringItemMap = (): Map<number, Types.ScoringItem> => {
    const question = useQuestion();
    return React.useMemo(() => {
      return question.scoringItems.reduce<Map<number, Types.ScoringItem>>((all, item) => {
        all.set(item.systemDesignScoringItemId, item);
        return all;
      }, new Map());
    }, [question.scoringItems]);
  };

  const useSubmissions = () => {
    return entity.submissions;
  };

  const useTotalElapsedTimeSeconds = (): number => {
    return React.useMemo((): number => {
      return entity.submissions.reduce<number>((total, submission) => {
        return total + (submission.elapsedTimeSeconds ?? 0);
      }, 0);
    }, []);
  };

  const useCurrentSubmission = () => {
    return entity.submissions.find(submission => submission.systemDesignSubmissionId === currentSelectedSubmissionId);
  };

  const useCurrentSelectedSubmissionId = () => {
    return currentSelectedSubmissionId;
  };

  const useLastEvaluation = () => {
    const currentSubmission = useCurrentSubmission();
    if (!currentSubmission) {
      return;
    }
    return currentSubmission.evaluations[currentSubmission.evaluations.length - 1];
  };

  const useInitialFlowChart = () => {
    const question = useQuestion();
    return parseFlowChartSnapshot(question.initialSnapshot);
  };

  const useCurrentSelectedSubmissionSnapshot = () => {
    const submission = useCurrentSubmission();
    return parseFlowChartSnapshot(submission?.snapshot || "");
  };

  const useNumOfRun = (): number => {
    const currentSubmission = useCurrentSubmission();
    return currentSubmission?.snapshots.length ?? 0;
  };

  const useNumOfUsedHint = (): number => {
    const currentSubmission = useCurrentSubmission();
    return currentSubmission?.usedHints.length ?? 0;
  };

  const useTestResults = () => {
    const lastEvaluation = useLastEvaluation();
    if (!lastEvaluation) {
      return;
    }
    try {
      const testResult = JSON.parse(lastEvaluation.result);
      const parseResult = validator.safeParse(testResult);

      if (parseResult.success) {
        return parseResult.data;
      }
    } catch (error) {
      return;
    }
  };

  const useAggregatedScoringItemMap = (): Types.AggregatedScoringItemMap => {
    const testResults = useTestResults();
    const scoringItemMap = useScoringItemMap();
    const initialScoringItemMap: Types.AggregatedScoringItemMap = {
      availability: Def.generateScoreTarget(),
      scalability: Def.generateScoreTarget(),
      consistency: Def.generateScoreTarget(),
      all: Def.generateScoreTarget(),
    };
    if (!testResults) {
      return initialScoringItemMap;
    }
    return testResults.reduce<Types.AggregatedScoringItemMap>((aggregateResult, testResult, index) => {
      const item = scoringItemMap.get(testResult.scoring_item_id);
      if (!item) {
        return aggregateResult;
      }
      const isPassed = testResult.is_passed;
      const addValue = isPassed ? 1 : 0;
      switch (item.category) {
        case "SCALABILITY":
        case "CONSISTENCY":
        case "AVAILABILITY": {
          const key = Def.categoryMap[item.category];
          return {
            ...aggregateResult,
            [key]: {
              numOfPassed: aggregateResult[key].numOfPassed + addValue,
              numOfQuestions: aggregateResult[key].numOfQuestions + 1,
              items: aggregateResult[key].items.concat({ ...item, index, isPassed, testResult }),
            },
            all: {
              numOfPassed: aggregateResult.all.numOfPassed + addValue,
              numOfQuestions: aggregateResult.all.numOfQuestions + 1,
              items: aggregateResult.all.items.concat({ ...item, index, isPassed, testResult }),
            },
          };
        }
        default:
          return aggregateResult;
      }
    }, initialScoringItemMap);
  };

  const useTotalScore = () => {
    return entity.totalScore;
  };

  const useRankEvaluation = () => {
    return entity.rankEvaluation;
  };

  const useCalculatedScoringItemMap = () => {
    const aggregatedScoringItemMap = useAggregatedScoringItemMap();
    const targets = ["availability", "scalability", "consistency"] as const;
    const total = targets.reduce(
      (all, current) => {
        const { numOfPassed, numOfQuestions } = aggregatedScoringItemMap[current];
        return { numOfPassed: all.numOfPassed + numOfPassed, numOfQuestions: all.numOfQuestions + numOfQuestions };
      },
      { numOfPassed: 0, numOfQuestions: 0 },
    );
    return {
      total: {
        score: total.numOfQuestions > 0 ? Math.floor((total.numOfPassed / total.numOfQuestions) * 100) : 0,
      },
      availability: (aggregatedScoringItemMap.availability.items.length > 0 || null) && {
        score: Math.floor((aggregatedScoringItemMap.availability.numOfPassed / aggregatedScoringItemMap.availability.numOfQuestions) * 100),
      },
      scalability: (aggregatedScoringItemMap.scalability.items.length > 0 || null) && {
        score: Math.floor((aggregatedScoringItemMap.scalability.numOfPassed / aggregatedScoringItemMap.scalability.numOfQuestions) * 100),
      },
      consistency: (aggregatedScoringItemMap.consistency.items.length > 0 || null) && {
        score: Math.floor((aggregatedScoringItemMap.consistency.numOfPassed / aggregatedScoringItemMap.consistency.numOfQuestions) * 100),
      },
    };
  };

  const useStatistics = () => {
    const statistics = entityState.statisticsMap.get(entityState.queryKey);
    return {
      elapsedAvgTimeSeconds: statistics?.elapsedTime?.avg ?? 0,
      numOfRunAvg: Math.round(statistics?.numSnapshots?.avg ?? 0),
      numOfHintAvg: Math.round(statistics?.numHints?.avg ?? 0),
      numTabEventAvg: Math.round(statistics?.numHints?.avg ?? 0),
    };
  };

  const useAppealMessage = () => {
    return entity.appealMessage;
  };

  return {
    useCurrentSelectedSubmissionId,
    useCurrentSubmission,
    useQuestionId,
    useAppealMessage,
    useInitialFlowChart,
    useSystemDesignId,
    useCurrentSelectedSubmissionSnapshot,
    useStatistics,
    useNumOfRun,
    useNumOfUsedHint,
    useCalculatedScoringItemMap,
    useTestResults,
    useLastEvaluation,
    useSystemDesign,
    useQuestion,
    useAggregatedScoringItemMap,
    useSubmissions,
    useTotalElapsedTimeSeconds,
    useTotalScore,
    useRankEvaluation,
  };
};
