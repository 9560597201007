import * as ProjectHelper from "@hireroo/app-helper/project-v3";
import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import { formatScore } from "@hireroo/formatter/score";
import * as Graphql from "@hireroo/graphql/client/urql";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type BlockNode = Widget.ProjectV3DefaultTestCaseDialogProps["blockBuilder"]["blocks"][0]["node"];

type DefaultTestCaseSchema = {
  blocks: BlockNode[];
};

export type GenerateTestCaseSectionPropsForDefaultArgs = {
  entityId: number;
  uniqueKey: ProjectTestReport.UniqueKey;
  showScore: boolean;
};

export const useGenerateTestCaseSectionPropsForDefault = (
  args: GenerateTestCaseSectionPropsForDefaultArgs,
): Widget.ProjectTestReportProps["testCaseSection"] => {
  const hooks = ProjectTestReport.useCreateProjectHooks(args.entityId);
  const submission = hooks.useCurrentSubmission();
  const projectVersion = hooks.useProjectVersion();
  const parsedValue = React.useMemo((): DefaultTestCaseSchema => {
    if (!submission?.testResult || projectVersion === Graphql.ProjectVersion.V4) {
      return {
        blocks: [],
      };
    }

    return ProjectHelper.DefaultTestCase.parseDefaultTestCase(submission.testResult);
  }, [projectVersion, submission?.testResult]);

  return {
    scrollTargetElementId: ProjectTestReport.TargetElementIdMap.CORRECT_RATE_SECTION,
    scoreAnswerRateDisplayLabel: {
      numPassed: submission?.numPassed ?? 0,
      numTests: submission?.numTests ?? 0,
    },
    titleWithScoreBar: {
      score: args.showScore ? formatScore(submission?.coverage ?? 0) : undefined,
    },
    content: {
      kind: "DEFAULT",
      testCases: {
        blocks: parsedValue.blocks.map(node => {
          return { node };
        }),
      },
    },
  };
};
