import { TestRankVisualizer } from "@hireroo/app-store/widget/shared/TestRankVisualizer";
import type { Widget } from "@hireroo/presentation";

export type GenerateTestRankVisualizerPropsArgs = {
  enableTagField: boolean;
  enableRank: boolean;
  rankEvaluation: string;
  readOnly?: boolean;
};

export const useGenerateProps = (args: GenerateTestRankVisualizerPropsArgs): Widget.TestRankVisualizerProps => {
  const binsData = TestRankVisualizer.useBins();
  const benchMarks = TestRankVisualizer.useBenchMarks(args.enableRank, args.rankEvaluation);
  const query = TestRankVisualizer.useQuery();
  const graphStatus = TestRankVisualizer.useGraphStatus();
  const tags = TestRankVisualizer.useTags();
  const now = new Date();

  return {
    status: graphStatus,
    queryToolbar: {
      readOnly: args.readOnly,
      enableTagField: args.enableTagField,
      defaultValues: {
        ...query,
        tags: tags.map(tag => {
          return {
            value: tag.name,
            selected: false,
          };
        }),
      },
      onChange: fields => {
        TestRankVisualizer.updateQuery({
          ...fields,
          tags: fields.tags.filter(tag => tag.selected),
        });
      },
      startDatePicker: {
        max: query.endDate || now,
        datePicker: {
          maxDate: query.endDate || now,
        },
      },
      endDatePicker: {
        min: query.startDate ?? undefined,
        max: now,
        datePicker: {
          minDate: query.startDate ?? undefined,
          maxDate: now,
        },
      },
    },
    areaHistogram: {
      data: binsData,
      lines: benchMarks,
    },
  };
};
