import * as Graphql from "@hireroo/graphql/client/urql";
import { useSnapshot } from "valtio";

import { state } from "./State";
import type * as Types from "./types";

export const useSnapshotState = () => {
  return useSnapshot(state);
};

export const useInitialized = (entityId: Types.ProjectEntityId) => {
  const snapshot = useSnapshotState();
  return snapshot.projects.has(entityId);
};

export const useCreateProjectHooks = (entityId: Types.ProjectEntityId) => {
  const snapshot = useSnapshotState();
  const projectState = snapshot.projects.get(entityId);
  if (!projectState) {
    throw new Error(`Please initialize projectId=${entityId}`);
  }

  const { entity, currentSelectedSubmissionId } = projectState;

  const useCurrentSelectedSubmissionId = () => {
    return currentSelectedSubmissionId;
  };

  const useStatus = () => {
    return entity.projectStatus;
  };

  const useProjectVersion = (): Graphql.ProjectVersion => {
    if (!entity.question) {
      throw new Error("Please initialize question");
    }
    return entity.question.projectVersion;
  };

  const useSubmissions = () => {
    return entity.submissions;
  };

  const useCurrentSubmission = () => {
    return entity.submissions.find(submission => submission.projectSubmissionId === currentSelectedSubmissionId);
  };

  const useStrictCurrentSubmission = () => {
    const submission = useCurrentSubmission();
    if (!submission) {
      throw new Error("Please select a submission");
    }
    return submission;
  };

  const useCurrentSubmissionStatus = () => {
    const submission = useCurrentSubmission();
    return submission?.submissionStatus;
  };

  const useCurrentSubmissionFailureReason = () => {
    const submission = useCurrentSubmission();
    return submission?.failureReason;
  };

  const useQuestionId = () => {
    return entity.question?.questionId;
  };

  const useQuestion = () => {
    return entity.question;
  };

  const useTotalScore = () => {
    return entity.totalScore;
  };

  const useRankEvaluation = () => {
    return entity.rankEvaluation;
  };

  const useQuestionVariant = () => {
    return entity.question?.variant;
  };

  const useSubmittedStatistics = () => {
    const currentSubmission = useCurrentSubmission();
    return {
      totalElapsedTimeSeconds: currentSubmission?.totalElapsedTimeSeconds ?? 0,
      numOfRun: currentSubmission?.snapshots.length ?? 0,
      avgLatency: currentSubmission?.avgLatency ?? 0,
      sla: typeof currentSubmission?.avgSla === "number" ? Math.floor(currentSubmission.avgSla * 100) : null,
    };
  };

  const useStatistics = () => {
    const statistics = projectState.statisticsMap.get(projectState.queryKey) ?? null;

    return {
      averageElapsedTimeSeconds: statistics?.elapsedTime?.avg ?? 0,
      averageCodeExecutions: Math.round(statistics?.numSnapshots?.avg ?? 0),
      averageLatency: statistics?.avgLatency?.avg ?? 0,
      averageSla: Math.floor((statistics?.avgSla?.avg ?? 0) * 100),
      averageNumTabEvents: statistics?.numTabEvents?.avg ?? 0,
    };
  };

  const useAppealMessage = () => {
    return entity.appealMessage;
  };

  return {
    useStatus,
    useProjectVersion,
    useTotalScore,
    useRankEvaluation,
    useSubmittedStatistics,
    useCurrentSubmission,
    useCurrentSubmissionStatus,
    useCurrentSubmissionFailureReason,
    useCurrentSelectedSubmissionId,
    useStrictCurrentSubmission,
    useQuestion,
    useQuestionId,
    useSubmissions,
    useStatistics,
    useQuestionVariant,
    useAppealMessage,
  };
};
