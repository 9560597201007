import { DefaultTestCaseDialog, type DefaultTestCaseDialogProps } from "@hireroo/project/react/v2/widget";
import Box from "@mui/material/Box";
import * as React from "react";

import BlockBuilder, { BlockBuilderProps } from "../../modules/BlockBuilder/BlockBuilder";

export type ProjectV3DefaultTestCaseDialogProps = {
  open: boolean;
  blockBuilder: BlockBuilderProps;
  onClose: () => void;
  failureMessage?: string;
};

const ProjectV3DefaultTestCaseDialog: React.FC<ProjectV3DefaultTestCaseDialogProps> = props => {
  const defaultTestCaseDialogProps: DefaultTestCaseDialogProps = {
    open: props.open,
    onClose: () => {
      props?.onClose();
    },
    children: (
      <Box p={3}>
        <BlockBuilder {...props.blockBuilder} />
      </Box>
    ),
  };

  return <DefaultTestCaseDialog {...defaultTestCaseDialogProps} />;
};

ProjectV3DefaultTestCaseDialog.displayName = "ProjectV3DefaultTestCaseDialog";

export default ProjectV3DefaultTestCaseDialog;
