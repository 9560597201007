import { BackendTestCaseDialogV3, type BackendTestCaseDialogV3Props } from "@hireroo/project/react/v2/widget";
import * as React from "react";

export type ProjectV3BackendTestCaseDialogProps = Pick<BackendTestCaseDialogV3Props, "items"> & {
  open: boolean;
  onClose: () => void;
};

const ProjectV3BackendTestCaseDialog: React.FC<ProjectV3BackendTestCaseDialogProps> = props => {
  const backendTestCaseDialogProps: BackendTestCaseDialogV3Props = {
    ...props,
    open: props.open,
    onClose: () => {
      props?.onClose?.();
    },
  };
  return <BackendTestCaseDialogV3 {...backendTestCaseDialogProps} />;
};

ProjectV3BackendTestCaseDialog.displayName = "ProjectV3BackendTestCaseDialog";

export default ProjectV3BackendTestCaseDialog;
