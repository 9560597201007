import { useTranslation } from "@hireroo/i18n";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

type Status = "READY" | "PENDING" | "SUCCESS" | "FAILED";

export type TestCaseItemProps = {
  expanded: boolean;
  status: Status;
  Result: React.ReactNode;
  header: {
    mainText: string;
    subText: string | React.ReactNode;
  };
  content: {
    title: string;
    description: string;
  };
  runButton: Pick<ButtonProps, "onClick">;
};

const MarkComponentMap: Record<Status, React.ReactNode> = {
  READY: <CircleOutlinedIcon color="disabled" />,
  PENDING: <CircularProgress size="20px" />,
  SUCCESS: <CheckCircleIcon color="success" />,
  FAILED: <CancelIcon color="error" />,
};

const StyledAccordion = styled(Accordion)(() => ({
  padding: "12px 16px",
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  display: "flex",
  alginItems: "center",
  justifyContent: "center",
  height: "30px",
  minHeight: "30px",
  paddingLeft: 0,
  paddingRight: 0,
}));

const IconBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  wordBreak: "keep-all",
  width: "24px",
  marginRight: 16,
}));

const StyledTypography = styled(Typography)(() => ({
  alignItems: "center",
  display: "flex",
  wordBreak: "break-all",
}));

const TestDescriptionWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette["Background/Paper"].p8,
  borderRadius: "8px",
  padding: "8px 16px",
}));

const TestCaseItem: React.FC<TestCaseItemProps> = props => {
  const { t } = useTranslation();
  const runButton: ButtonProps = {
    ...props.runButton,
    children: t("実行"),
    color: "secondary",
    startIcon: <PlayArrowIcon />,
    disabled: props.status === "PENDING",
    onClick: event => {
      // Runボタンを押した際にAccordionを開くのを阻止する
      event.stopPropagation();
      props.runButton.onClick?.(event);
    },
  };

  const [expanded, setExpanded] = React.useState<boolean>(false);

  const tooltipTextMap: Record<Status, string> = {
    READY: "",
    PENDING: t("実行中はクリックできません"),
    SUCCESS: "",
    FAILED: "",
  };

  const accordionProps: Omit<AccordionProps, "children"> = {
    expanded: expanded,
    onClick: () => {
      setExpanded(prev => {
        return !prev;
      });
    },
  };

  React.useEffect(() => {
    if (props.status === "SUCCESS" || props.status === "FAILED") {
      setExpanded(props.expanded);
    } else {
      /** statusがREADY, PENDINGに変化したら結果を表示する領域を閉じる */
      setExpanded(false);
    }
  }, [props.expanded, props.status]);

  return (
    <StyledAccordion {...accordionProps}>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <IconBox>{MarkComponentMap[props.status]}</IconBox>
        <StyledTypography variant="body2" mr={1}>
          {props.header.mainText}
        </StyledTypography>
        <StyledTypography variant="body2" color="text.secondary">
          {props.header.subText}
        </StyledTypography>
        <Box sx={{ flexGrow: 1 }} />
        <Tooltip title={tooltipTextMap[props.status]}>
          <span>
            <Button {...runButton} />
          </span>
        </Tooltip>
      </StyledAccordionSummary>
      <AccordionDetails>
        {(props.content.title || props.content.description) && (
          <TestDescriptionWrapper>
            {props.content.title && (
              <Box mb={1}>
                <StyledTypography variant="body2">{props.content.title}</StyledTypography>
              </Box>
            )}
            {props.content.description && (
              <Box>
                <StyledTypography variant="body2">{props.content.description}</StyledTypography>
              </Box>
            )}
          </TestDescriptionWrapper>
        )}
        {
          <Box>
            {typeof props.Result === "string" ? (
              <Typography variant="body2" color="text.secondary" py={2}>
                {props.Result}
              </Typography>
            ) : (
              props.Result
            )}
          </Box>
        }
      </AccordionDetails>
    </StyledAccordion>
  );
};

TestCaseItem.displayName = "TestCaseItem";

export default TestCaseItem;
