import { useFailureMessageMap } from "@hireroo/app-definition/project";
import * as ProjectHelper from "@hireroo/app-helper/project-v3";
import { ProjectCodingEditorV3 } from "@hireroo/app-store/widget/shared/ProjectCodingEditorV3";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

type BlockNode = Widget.ProjectV3DefaultTestCaseDialogProps["blockBuilder"]["blocks"][0]["node"];

type DefaultTestCaseSchema = {
  blocks: BlockNode[];
};

export type GenerateProjectV3DefaultTestCaseDialogPropsArgs = {
  open: boolean;
  onClose: () => void;
  entityId: number;
  testCaseResult: string;
};

export const useGenerateProps = (args: GenerateProjectV3DefaultTestCaseDialogPropsArgs): Widget.ProjectV3DefaultTestCaseDialogProps => {
  const action = ProjectCodingEditorV3.createProjectEntityAction(args.entityId);
  const hooks = ProjectCodingEditorV3.useCreateProjectEntityHooks(args.entityId);
  const testCaseState = hooks.useDefaultTestCaseState();
  const snapshotIsFailed = testCaseState?.snapshotStatus === "FAILED";

  const parsedValue = React.useMemo((): DefaultTestCaseSchema => {
    if (!args.open) {
      return {
        blocks: [],
      };
    }
    return ProjectHelper.DefaultTestCase.parseDefaultTestCase(args.testCaseResult);
  }, [args.open, args.testCaseResult]);

  const failureMessageMap = useFailureMessageMap();

  return {
    open: args.open,
    blockBuilder: {
      blocks: parsedValue.blocks.map(node => {
        return { node };
      }),
    },
    onClose: () => {
      args.onClose();
      action.clearTestResultForDefault();
    },
    failureMessage: snapshotIsFailed && testCaseState.failureReason ? failureMessageMap[testCaseState.failureReason] : undefined,
  };
};
