import { useEnabledProjectReportV4 } from "@hireroo/app-helper/feature";
import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import * as Graphql from "@hireroo/graphql/client/urql";
import type { Widget } from "@hireroo/presentation";

import EvaluationTestResultContainer from "./widget/EvaluationTestResult/Container";

export type GenerateTestResultSectionPropsArgs = {
  entityId: number;
};

export const useGenerateTestResultSectionProps = (
  args: GenerateTestResultSectionPropsArgs,
): Widget.ProjectTestReportProps["TestResultSection"] => {
  const hooks = ProjectTestReport.useCreateProjectHooks(args.entityId);
  const enabledProjectReportV4 = useEnabledProjectReportV4();
  const projectVersion = hooks.useProjectVersion();

  if (!enabledProjectReportV4 || projectVersion !== Graphql.ProjectVersion.V4) {
    return undefined;
  }

  return <EvaluationTestResultContainer {...args} />;
};
