import { generateKey } from "@hireroo/app-helper/parser";
import * as Graphql from "@hireroo/graphql/client/urql";
import { proxyMap } from "valtio/utils";

import { state } from "./State";
import type * as Types from "./types";
import * as ProjectContentsViewerV3 from "./widget/ProjectContentsViewerV3";

export const initialize = (entity: Types.ProjectEntity): void => {
  const bestSubmission = entity.submissions.find(submission => submission.isBest);
  const currentSelectedSubmissionId = bestSubmission?.projectSubmissionId ?? 0;
  state.projects.set(entity.projectEntityId, {
    queryKey: "",
    statisticsMap: proxyMap(),
    entity: entity,
    currentSelectedSubmissionId: currentSelectedSubmissionId,
  });
  setCurrentSelectedSubmissionId(entity.projectEntityId, currentSelectedSubmissionId);
};

export const clearEntity = (entityId: Types.ProjectEntityId) => {
  state.projects.delete(entityId);
};

export const setQuery = (entityId: Types.ProjectEntityId, query: Types.Query) => {
  const projectState = state.projects.get(entityId);
  if (!projectState) {
    return;
  }
  projectState.queryKey = generateKey(query);
};

export const setStatistics = (entityId: Types.ProjectEntityId, query: Types.Query, result: Types.Statistics | null) => {
  const projectState = state.projects.get(entityId);
  if (!projectState || !result) {
    return;
  }
  projectState.statisticsMap.set(generateKey(query), result);
  setQuery(entityId, query);
};

export const setCurrentSelectedSubmissionId = (entityId: Types.ProjectEntityId, currentSelectedSubmissionId: Types.SubmissionId) => {
  const entityState = state.projects.get(entityId);

  if (entityState) {
    entityState.currentSelectedSubmissionId = currentSelectedSubmissionId;
    const submission = entityState.entity.submissions.find(submission => submission.projectSubmissionId === currentSelectedSubmissionId);
    if (submission) {
      if (entityState.entity.question?.projectVersion.startsWith("v2")) {
        // Do nothing here since submission related state is filled in other place
      } else if (
        entityState.entity.question?.projectVersion === Graphql.ProjectVersion.V3 ||
        entityState.entity.question?.projectVersion === Graphql.ProjectVersion.V4
      ) {
        const action = ProjectContentsViewerV3.createAction(entityId);
        action.setSubmission(submission);
      }
    }
  }
};

export const setSubmission = (entityId: Types.ProjectEntityId, submission: Types.Submission) => {
  const project = state.projects.get(entityId);
  if (!project) {
    return;
  }
  const index = project.entity.submissions.findIndex(s => s.projectSubmissionId === submission.projectSubmissionId);
  if (index === -1) {
    return;
  }
  project.entity.submissions[index] = submission;
};
