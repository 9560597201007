import { NO_RESPONSE_STATUS_CODE } from "@hireroo/app-definition/project";
import { useFailureMessageMap } from "@hireroo/app-definition/project";
import * as ProjectHelper from "@hireroo/app-helper/project-v3";
import { ProjectCodingEditorV3 } from "@hireroo/app-store/widget/shared/ProjectCodingEditorV3";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import * as JSONFormatter from "@hireroo/formatter/json";
import * as Time from "@hireroo/formatter/time";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { ProjectFailureReason } from "@hireroo/graphql/server/types";
import { useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown-v2/react";
import type { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";
import * as React from "react";

import type { Mode } from "../../types";
import * as PrivateHelpers from "./privateHelper";

type Item = Widget.ProjectV3BackendTestCaseDialogProps["items"][0];

export type GenerateProjectV3BackendTestCaseDialogPropsArgs = {
  open: boolean;
  onClose: () => void;
  entityId: number;
  mode: Mode;
};

export const useGenerateProps = (args: GenerateProjectV3BackendTestCaseDialogPropsArgs): Widget.ProjectV3BackendTestCaseDialogProps => {
  const action = ProjectCodingEditorV3.createProjectEntityAction(args.entityId);
  const hooks = ProjectCodingEditorV3.useCreateProjectEntityHooks(args.entityId);
  const question = hooks.useQuestion();
  const workspace = hooks.useWorkspace();
  const testCaseStateMap = hooks.useTestCaseState();
  const testCaseResultMap = hooks.useBackendTestCaseResultMap();
  const testCases = React.useMemo(() => {
    if (!question?.correctnessTestCase) {
      return [];
    }
    // TODO: Parse version first so that we can use parser depending on the version
    return ProjectHelper.BackendTestCase.parseBackendTestCases(question.correctnessTestCase)?.test_cases ?? [];
  }, [question?.correctnessTestCase]);
  const generateTestResultDescription = PrivateHelpers.useGetTestResultDescriptionGeneratorForBackend();

  const { t } = useTranslation();
  const client = getGraphqlClient();

  const snapshotFailureMessageMap = useFailureMessageMap();

  const testCaseFailureMessageMap = {
    SERVER_HEALTH_CHECK_FAILED: t(
      "サーバーの起動確認に失敗しました。ソースコードがビルドできているか、また既存のヘルスチェックの実装に変更を加えていないかをご確認ください。",
    ),
    EVALUATION_PREPARATION_FAILED: t(
      "評価プロセス中にエラーが発生しました。評価用に用意されている既存のエンドポイントの実装に変更を加えていないかをご確認ください。",
    ),
  } as const satisfies Record<string, string>;

  const getFailureMessage = (
    snapshotIsFailed: boolean,
    testCaseIsFailed: boolean,
    snapshotFailureReason: ProjectFailureReason | undefined,
    testCaseFailureReason: "SERVER_HEALTH_CHECK_FAILED" | "EVALUATION_PREPARATION_FAILED" | undefined,
  ): string => {
    if (snapshotIsFailed) {
      return snapshotFailureReason
        ? snapshotFailureMessageMap[snapshotFailureReason]
        : t("予期しないエラーによりテストケースの実行に失敗しました。運営までお問合せください。");
    } else if (testCaseIsFailed) {
      return testCaseFailureReason
        ? testCaseFailureMessageMap[testCaseFailureReason]
        : t("予期しないエラーによりテストケースの実行に失敗しました。運営までお問合せください。");
    } else {
      return t("予期しないエラーによりテストケースの実行に失敗しました。運営までお問合せください。");
    }
  };

  return {
    open: args.open,
    onClose: () => {
      args.onClose();
      action.clearTestResultForBackend();
    },
    items: testCases
      .filter(testCase => !testCase.is_hidden)
      .map((testCase, tcIndex): Item => {
        const key = `${JSON.stringify(testCase)}-${tcIndex}`;
        const testResult = testCaseResultMap.get(key);
        const testCaseState = testCaseStateMap.get(key);
        const snapshotIsFailed = testCaseState?.snapshotStatus === "FAILED";
        const testCaseIsFailed = testCaseState?.testCaseStatus === "FAILED";
        const testCaseIsPassed = testResult?.results.every((resultPerCommand, i) => {
          return ProjectHelper.BackendTestCase.withinRange(resultPerCommand.status, testCase.test_case_commands[i].status);
        });

        return {
          forceOpen: snapshotIsFailed || !!testResult,
          status: testCaseState ? testCaseState.status : "DEFAULT",
          title: `${t("テストケース")} ${tcIndex + 1}`,
          runTestCaseButton: {
            onClick: () => {
              Snackbar.notify({
                severity: "info",
                message: t("実行が開始しました。処理が終わるまでしばらくお待ち下さい。"),
              });
              action.setTestCaseState(key, {
                status: "LOADING",
              });

              if (!workspace) {
                Snackbar.notify({
                  severity: "error",
                  message: t("実行に失敗しました。運営までお問い合わせください。"),
                });
                Sentry.captureException("failed to run project for project since workspace is not found");
                return;
              }

              client
                .RunProjectForProjectCodingEditor({
                  input: {
                    projectId: args.entityId,
                    questionId: question.questionId,
                    questionVersion: question.version,
                    testCase: JSON.stringify(testCase),
                    workspaceId: workspace.id,
                    takeSnapshot: args.mode === "DEVELOPMENT",
                  },
                })
                .then(res => {
                  Snackbar.notify({
                    severity: "success",
                    message: t("実行が完了しました。結果を確認してください。"),
                  });

                  if (res.runProject.status === "FAILED") {
                    return action.setTestCaseState(key, {
                      status: "FAILED",
                      snapshotStatus: res.runProject.status,
                      failureReason: res.runProject.failureReason,
                    });
                  }

                  const testCaseResult = ProjectHelper.BackendTestCase.parseBackendTestCaseResult(res.runProject.testResult);
                  if (!testCaseResult) {
                    /**
                     * TODO Decide how to behave when TestCase fails to parse successfully.
                     */
                    return;
                  }

                  action.setTestCaseResultForBackend(key, testCaseResult);
                  action.setTestCaseState(key, {
                    status: testCaseResult.is_passed ? "SUCCEEDED" : "FAILED",
                    snapshotStatus: res.runProject.status,
                    failureReason: res.runProject.failureReason,
                    testCaseStatus: testCaseResult.status,
                    testCaseFailureReason: testCaseResult.failure_reason !== "" ? testCaseResult.failure_reason : undefined,
                  });
                })
                .catch(() => {
                  action.setTestCaseState(key, {
                    /**
                     * TODO Consider whether to set status to FAILED in case of error after migration
                     */
                    status: "DEFAULT",
                  });
                  Snackbar.notify({
                    severity: "error",
                    message: t("実行に失敗しました。ソースコードを確認してください。"),
                  });
                });
            },
          },
          testResult: !testResult
            ? undefined
            : {
                /**
                 * TODO: consider putting test cases inside of the submission
                 */
                tcIndex,
                status: testCaseIsPassed ? "SUCCESS" : "ERROR",
                testResult: {
                  kind: "MULTI",
                  values: testResult.results.map((result, commandIndex) => {
                    return {
                      index: commandIndex + 1,
                      method: testCase.test_case_commands[commandIndex].method,
                      path: testCase.test_case_commands[commandIndex].path,
                      query: testCase.test_case_commands[commandIndex].query,
                      status: result.is_passed ? "SUCCESS" : "ERROR",
                      httpStatusCode: result.status.toString(),
                      latency: Time.getTimeUnitText(result.latency, Time.Unit.MILLISECOND),
                      codeEditor: {
                        value: JSONFormatter.formatJson(testCase.test_case_commands[commandIndex].body, "{}"),
                        /**
                         * @see https://github.com/suren-atoyan/monaco-react#multi-model-editor
                         */
                        path: `tc-${tcIndex}-command-${commandIndex}`,
                      },
                      diffEditor: {
                        originalModelPath: `original-test-result-${tcIndex}-command-${commandIndex}`,
                        original: JSONFormatter.prettyJsonString(result.output, "{}"),
                        modifiedModelPath: `modified-test-result-${tcIndex}-command-${commandIndex}`,
                        modified: JSONFormatter.prettyJsonString(result.expected, "{}"),
                      },
                      ResultDescription: !snapshotIsFailed && !testCaseIsFailed && testCaseState?.status === "FAILED" && (
                        <React.Suspense>
                          <Markdown
                            size="small"
                            children={generateTestResultDescription({
                              command: testCase.test_case_commands[commandIndex],
                              resultPerCommand: result,
                            })}
                          />
                        </React.Suspense>
                      ),
                      alternativeMessage:
                        result.status === NO_RESPONSE_STATUS_CODE
                          ? t(
                              "レスポンスの取得に失敗しました。リクエストの処理中にサーバーが停止した可能性があります。ソースコードをご確認ください。",
                            )
                          : undefined,
                    };
                  }),
                },
              },
          failureDescription:
            snapshotIsFailed || testCaseIsFailed
              ? getFailureMessage(snapshotIsFailed, testCaseIsFailed, testCaseState?.failureReason, testCaseState?.testCaseFailureReason)
              : undefined,
        };
      }),
  };
};
