import { useFailureMessageMap } from "@hireroo/app-definition/project";
import * as ProjectHelper from "@hireroo/app-helper/project-v3";
import { ProjectCodingEditorV3 } from "@hireroo/app-store/widget/shared/ProjectCodingEditorV3";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { ProjectFailureReason } from "@hireroo/graphql/server/types";
import { useTranslation } from "@hireroo/i18n";
import Markdown from "@hireroo/markdown-v2/react";
import type { Widget } from "@hireroo/presentation";
import * as Sentry from "@sentry/react";
import * as React from "react";

import type { Mode } from "../../types";
import * as PrivateHelpers from "./privateHelper";

type Item = Widget.ProjectV3FrontendTestCaseDialogProps["items"][0];

export type GenerateProjectV3FrontendTestCaseDialogPropsArgs = {
  open: boolean;
  entityId: number;
  mode: Mode;
  onClose: () => void;
};

export const useGenerateProps = (args: GenerateProjectV3FrontendTestCaseDialogPropsArgs): Widget.ProjectV3FrontendTestCaseDialogProps => {
  const action = ProjectCodingEditorV3.createProjectEntityAction(args.entityId);
  const hooks = ProjectCodingEditorV3.useCreateProjectEntityHooks(args.entityId);
  const question = hooks.useQuestion();
  const workspace = hooks.useWorkspace();
  const testCaseResultMap = hooks.useFrontendTestCaseResultMap();
  const testCaseStateMap = hooks.useTestCaseState();
  const testCases = React.useMemo(() => {
    if (!question?.correctnessTestCase) {
      return [];
    }
    // TODO: Parse version first so that we can use parser depending on the version
    return ProjectHelper.FrontendTestCase.parseFrontendTestCases(question.correctnessTestCase)?.test_cases ?? [];
  }, [question?.correctnessTestCase]);
  const { t } = useTranslation();
  const client = getGraphqlClient();

  const scenarioTestAction = ProjectHelper.FrontendTestCase.useScenarioTestAction();
  const generateTestResultDescription = PrivateHelpers.useGetTestResultDescriptionGeneratorForFrontend();
  const failureMessageMap = useFailureMessageMap();

  const testCaseFailureMessageMap = {
    SERVER_HEALTH_CHECK_FAILED: t(
      "サーバーの起動確認に失敗しました。ソースコードがビルドできているか、また既存のヘルスチェックの実装に変更を加えていないかをご確認ください。",
    ),
    EVALUATION_PREPARATION_FAILED: t(
      "評価プロセス中にエラーが発生しました。評価用に用意されている既存のエンドポイントの実装に変更を加えていないかをご確認ください。",
    ),
  } as const satisfies Record<string, string>;

  const getFailureMessage = (
    snapshotIsFailed: boolean,
    testCaseIsFailed: boolean,
    snapshotFailureReason: ProjectFailureReason | undefined,
    testCaseFailureReason: "SERVER_HEALTH_CHECK_FAILED" | "EVALUATION_PREPARATION_FAILED" | undefined,
  ): string => {
    if (snapshotIsFailed) {
      return snapshotFailureReason
        ? failureMessageMap[snapshotFailureReason]
        : t("予期しないエラーによりテストケースの実行に失敗しました。運営までお問合せください。");
    } else if (testCaseIsFailed) {
      return testCaseFailureReason
        ? testCaseFailureMessageMap[testCaseFailureReason]
        : t("予期しないエラーによりテストケースの実行に失敗しました。運営までお問合せください。");
    } else {
      return t("予期しないエラーによりテストケースの実行に失敗しました。運営までお問合せください。");
    }
  };

  return {
    open: args.open,
    onClose: () => {
      args.onClose();
      action.clearTestResultForFrontend();
    },
    items: testCases
      .filter(testCase => !testCase.is_hidden)
      .map((testCase, index): Item => {
        const key = `${JSON.stringify(testCase)}-${index}`;
        const testResult = testCaseResultMap.get(key);
        const testCaseState = testCaseStateMap.get(key);
        const snapshotIsFailed = testCaseState?.snapshotStatus === "FAILED";
        const testCaseIsFailed = testCaseState?.testCaseStatus === "FAILED";

        return {
          forceOpen: snapshotIsFailed || !!testResult,
          status: testCaseState ? testCaseState.status : "DEFAULT",
          title: `${t("テストケース")} ${index + 1}`,
          runTestCaseButton: {
            onClick: () => {
              Snackbar.notify({
                severity: "info",
                message: t("実行が開始しました。処理が終わるまでしばらくお待ち下さい。"),
              });
              action.setTestCaseState(key, {
                status: "LOADING",
              });

              if (!workspace) {
                Snackbar.notify({
                  severity: "error",
                  message: t("実行に失敗しました。運営までお問い合わせください。"),
                });
                Sentry.captureException("failed to run project for project since workspace is not found");
                return;
              }

              client
                .RunProjectForProjectCodingEditor({
                  input: {
                    projectId: args.entityId,
                    questionId: question.questionId,
                    questionVersion: question.version,
                    testCase: JSON.stringify(testCase),
                    workspaceId: workspace.id,
                    takeSnapshot: args.mode === "DEVELOPMENT",
                  },
                })
                .then(res => {
                  Snackbar.notify({
                    severity: "success",
                    message: t("実行が完了しました。結果を確認してください。"),
                  });
                  const testCaseResult = ProjectHelper.FrontendTestCase.parseFrontendCorrectnessTestCaseResult(res.runProject.testResult);
                  if (!testCaseResult) {
                    /**
                     * TODO Decide how to behave when TestCase fails to parse successfully.
                     */
                    return;
                  }
                  action.setTestCaseResultForFrontend(key, testCaseResult);
                  action.setTestCaseState(key, {
                    status: testCaseResult.is_passed ? "SUCCEEDED" : "FAILED",
                    snapshotStatus: res.runProject.status,
                    failureReason: res.runProject.failureReason,
                  });
                })
                .catch(() => {
                  action.setTestCaseState(key, {
                    status: "DEFAULT",
                  });
                  Snackbar.notify({
                    severity: "error",
                    message: t("実行に失敗しました。サーバーが起動しているか確認してください。"),
                  });
                });
            },
          },
          testResult: !testResult
            ? undefined
            : {
                title: `${t("テストシナリオ")} ${index + 1}`,
                status: testResult.is_passed ? "SUCCESS" : "ERROR",
                invisible: testResult.is_hidden ?? false,
                testResults: testResult.results.map((result, index) => {
                  return {
                    index: index + 1,
                    status: result.is_passed ? "SUCCESS" : "FAIL",
                    screenshotUrl: result.screenshot,
                    message: scenarioTestAction(testCase.test_case_commands[index]),
                  };
                }),
              },
          failureDescription:
            snapshotIsFailed || testCaseIsFailed
              ? getFailureMessage(snapshotIsFailed, testCaseIsFailed, testCaseState?.failureReason, testCaseState?.testCaseFailureReason)
              : undefined,
          TestResultDescription: testCaseState?.status === "FAILED" && (
            <React.Suspense>
              <Markdown size="small" children={generateTestResultDescription({ testCase, testResult, externalIp: "" })} />
            </React.Suspense>
          ),
        };
      }),
  };
};
