import { Widgets } from "@hireroo/evaluation-result-ui";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { GenerateEvaluationTestResultPropsArgs, useGenerateProps } from "./useGenerateProps";

export type EvaluationTestResultContainerProps = GenerateEvaluationTestResultPropsArgs;

const EvaluationTestResultContainer: React.FC<EvaluationTestResultContainerProps> = props => {
  const evaluationResultProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widgets.TestResult {...evaluationResultProps} />
    </ErrorBoundary>
  );
};

EvaluationTestResultContainer.displayName = "EvaluationTestResultContainer";

export default withErrorBoundary(EvaluationTestResultContainer, {});
