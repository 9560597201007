import { ShowingTarget } from "@hireroo/app-helper/entity";
import { QuizTestReport } from "@hireroo/app-store/view-domain/QuizTestReport";
import { formatSeconds } from "@hireroo/formatter/time";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

import { useGenerateEntityScoreBoardProps } from "../../../../props-factory/v2/view-domain/QuizTestReport/useGenerateEntityScoreBoardProps";
import { useGenerateQuestionDetailProps } from "../../../../props-factory/v2/view-domain/QuizTestReport/useGenerateQuestionDetailProps";
import { useGenerateSubmissionSectionProps } from "../../../../props-factory/v2/view-domain/QuizTestReport/useGenerateSubmissionSectionProps";
import QuizPlaybackEditorContainer from "../../../../props-factory/v2/view-domain/QuizTestReport/widget/QuizPlaybackEditor/Container";
import TotalScoreRankVisualizerInitialContainer from "../../shared/TotalScoreRankVisualizer/InitialContainer";
import { useGenerateHeaderProps } from "./useGenerateHeaderProps";

export type GenerateQuizTestReportPropsArgs = {
  showingTargets: ShowingTarget[];
  quizId: number;
  companyId: number;
  uniqueKey: QuizTestReport.UniqueKey;
};

export const useGenerateProps = (args: GenerateQuizTestReportPropsArgs): Widget.QuizTestReportProps => {
  const { t } = useTranslation();
  const lang = useLanguageCode();
  const hooks = QuizTestReport.useCreateQuizHooks(args.quizId);
  const quiz = hooks.useQuiz();
  const quizPackage = hooks.usePackage();
  const header = useGenerateHeaderProps({ quizId: args.quizId });
  const elapsedTimeSeconds = hooks.useTotalElapsedTimeSeconds();
  const statistics = hooks.useStatistics();
  const submissionSectionProps = useGenerateSubmissionSectionProps({
    quizId: args.quizId,
    uniqueKey: args.uniqueKey,
    showingTargets: [
      args.showingTargets.includes("STATISTICS") && ("STATISTIC" as const),
      "EDITOR" as const,
      args.showingTargets.includes("ANSWER") && ("ANSWER" as const),
      "PLAYBACK" as const,
    ].flatMap(v => (v ? [v] : [])),
    canEdit: false,
  });
  const questionDetail = useGenerateQuestionDetailProps({
    ...args,
    showAnswer: args.showingTargets.includes("ANSWER"),
    showArchivedMark: false,
  });
  const entityScoreBoard = useGenerateEntityScoreBoardProps({
    quizId: args.quizId,
    showScore: args.showingTargets.includes("SCORE"),
  });

  const questionIds = React.useMemo(() => {
    return quizPackage.questions.map(question => question.id);
  }, [quizPackage.questions]);

  return {
    header: header,
    entityScoreBoard: entityScoreBoard,
    answerDetailSection: {
      totalElapsedTime: {
        value: formatSeconds(elapsedTimeSeconds, lang),
        icon: {
          key: elapsedTimeSeconds <= statistics.elapsedAvgTimeSeconds ? "CHECK" : "WARNING",
          title: `${t("平均解答時間")} ${formatSeconds(statistics.elapsedAvgTimeSeconds, lang)}`,
        },
        canShowTooltip: args.showingTargets.includes("STATISTICS"),
      },
    },
    StatisticsContent: args.showingTargets.includes("STATISTICS") && quiz.quizStatus === "EVALUATED" && (
      <TotalScoreRankVisualizerInitialContainer
        uniqueKey={args.uniqueKey}
        score={quiz.totalScore}
        enableRank
        rankEvaluation={quiz.rankEvaluation}
      />
    ),
    submissionSection: submissionSectionProps,
    playbackSection: args.showingTargets.includes("PLAYBACK")
      ? {
          PlaybackComponent: (
            <QuizPlaybackEditorContainer key={args.quizId} quizId={args.quizId} packageId={quizPackage.packageId} questionIds={questionIds} />
          ),
        }
      : undefined,
    questionDetail: questionDetail,
  };
};
