import { TestResults } from "@hireroo/evaluation-result-interface";
import { useTranslation } from "@hireroo/i18n";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import * as z from "zod";

import ResultBlock from "../ResultBlocks/parts/ResultBlock/ResultBlock";
import DetailSwitch, { DetailSwitchProps } from "./parts/DetailSwitch/DetailSwitch";
import TestCase, { TestCaseProps } from "./parts/TestCase/TestCase";

export type TestResultProps = {
  rawData: string;
  onError: (error: z.ZodError) => void;
};

const TestResult: React.FC<TestResultProps> = props => {
  const { t } = useTranslation();
  const result = TestResults.safeParse(JSON.parse(props.rawData));
  const { onError } = props;
  const [expandAll, setExpandAll] = React.useState(false);

  React.useEffect(() => {
    if (result.error) {
      onError(result.error);
    }
  }, [onError, result.error]);

  if (!result.success) {
    return (
      <Typography fontSize={14}>{t("データの取得に失敗しました。しばらくしてから再度お試しいただくか、運営までお問合せください。")}</Typography>
    );
  }

  const detailSwitch: DetailSwitchProps = {
    checked: expandAll,
    onClick: () => {
      setExpandAll(prev => !prev);
    },
  };

  return (
    <Stack>
      <Typography variant="subtitle1" fontSize={16} fontWeight="bold" color="textSecondary" mb={3}>
        {t("テストケース")}
      </Typography>

      <Box display="flex" justifyContent="end" mb={2}>
        <DetailSwitch {...detailSwitch} />
      </Box>

      <Stack>
        {result.data.test_results.map((testResult, index) => {
          const testCase: TestCaseProps = {
            title: `${t("テストケース")} ${index + 1}`,
            icon: { kind: testResult.is_passed ? "CHECK" : "CROSS" },
            forceExpanded: expandAll,
          };
          return (
            <TestCase key={`testcase-${index}`} {...testCase}>
              {testResult.blocks.map((block, index) => (
                <ResultBlock key={`block-${index}`} {...block} />
              ))}
            </TestCase>
          );
        })}
      </Stack>
    </Stack>
  );
};

TestResult.displayName = "TestResult";

export default TestResult;
