import type * as Graphql from "@hireroo/graphql/client/graphql-request";
import { useTranslation } from "@hireroo/i18n";

export const useFailureMessageMap = () => {
  const { t } = useTranslation();
  const snapshotFailureMessageMap: Record<Graphql.ProjectFailureReason, string> = {
    WORKSPACE_PREPARATION_FAILED: t("テスト実行用の環境の構築に失敗したため評価に失敗しました。運営までお問合せください。"),
    SERVER_HEALTH_CHECK_FAILED: t(
      "サーバーの起動確認に失敗しました。ソースコードがビルドできているか、また既存のヘルスチェックの実装に変更を加えていないかをご確認ください。",
    ),
    EVALUATION_PREPARATION_FAILED: t(
      "評価プロセス中にエラーが発生しました。評価用に用意されている既存のエンドポイントの実装に変更を加えていないかをご確認ください。",
    ),
    BUILD_FAILED: t("サーバーの起動確認に失敗しました。ソースコードがビルドできているかご確認ください。"),
    SYSTEM_ERROR: t("システムエラーが発生しました。再度お試しいただくか、運営までお問い合わせください。"),
    UNKNOWN: t("予期しないエラーによりテストケースの実行に失敗しました。運営までお問合せください。"),
  };

  return snapshotFailureMessageMap;
};
