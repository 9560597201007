import { useEnabledProjectV4 } from "@hireroo/app-helper/feature";
import { BrowserWindowEventDetect, useFirebaseSender, useProjectRealtimeDatabase } from "@hireroo/app-helper/hooks";
import { ProjectCodingEditorV3 } from "@hireroo/app-store/widget/shared/ProjectCodingEditorV3";
import { ScreeningTestTutorial } from "@hireroo/app-store/widget/shared/ScreeningTestTutorial";
import { Snackbar } from "@hireroo/app-store/widget/shared/Snackbar";
import { getTimestamp } from "@hireroo/firebase";
import * as Graphql from "@hireroo/graphql/client/graphql-request";
import { getGraphqlClient } from "@hireroo/graphql/client/request";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import { resolveLanguage } from "@hireroo/i18n/utils";
import type { Widget } from "@hireroo/presentation";
import * as ProjectHelpers from "@hireroo/project/helpers/fileTree";
import { findNode } from "@hireroo/project/helpers/fileTree";
import { useFocusNode } from "@hireroo/project/helpers/hooks";
import { FirebaseStorePath, HistoriesPathsModel, IndexModel } from "@hireroo/project-shared-utils";
import * as Sentry from "@sentry/react";
import * as React from "react";

import ProjectTestCaseRunnerContainer from "../ProjectTestCaseRunner/Container";
import { useAgentServerHealthCheck, useApplicationServerHealthCheck, useEndpoints } from "./privateHelpers";
import { useGenerateAppealMessageProps } from "./useAppealMessageProps";
import { useGenerateTerminalProps } from "./useGenerateTerminalProps";
import { useStartDevelopmentWorkspace } from "./useStartDevelopmentWorkspace";
import ExtendedCodingEditorContainer, { ExtendedCodingEditorContainerProps } from "./widgets/ExtendedCodingEditor/Container";
import ProjectV3BackendTestCaseDialogContainer, {
  type ProjectV3BackendTestCaseDialogContainerProps,
} from "./widgets/ProjectV3BackendTestCaseDialog/Container";
import ProjectV3DataScienceTestCaseDialogContainer, {
  type ProjectV3DataScienceTestCaseDialogContainerProps,
} from "./widgets/ProjectV3DataScienceTestCaseDialog/Container";
import ProjectV3DefaultTestCaseDialogContainer, {
  type ProjectV3DefaultTestCaseDialogContainerProps,
} from "./widgets/ProjectV3DefaultTestCaseDialog/Container";
import ProjectV3FrontendTestCaseDialogContainer, {
  ProjectV3FrontendTestCaseDialogContainerProps,
} from "./widgets/ProjectV3FrontendTestCaseDialog/Container";
import { IMAGE_EXTENSION_PATTERN } from "./workspaceHelper/definition";
import { useFileTreeController } from "./workspaceHelper/developmentWorkspaceHooks";

export type GenerateProjectCodingEditorV3PropsArgs = {
  endpointId: number;
  uid: string;
  entityId: number;
  questionId: number;
  questionVersion: string;
  submissionId: number;
  isCandidate: boolean;
  enableBrowserEventDetector: boolean;
  userName: string;
  historiesPathsModel: HistoriesPathsModel;
  indexModel: IndexModel;
};

export const useGenerateProps = (args: GenerateProjectCodingEditorV3PropsArgs): Widget.ProjectCodingEditorV3Props => {
  const enabledProjectV4 = useEnabledProjectV4();
  const { historiesPathsModel } = args;
  const lang = useLanguageCode();
  const hooks = ProjectCodingEditorV3.useCreateProjectEntityHooks(args.entityId);
  const action = ProjectCodingEditorV3.createProjectEntityAction(args.entityId);
  const client = getGraphqlClient();
  const entity = hooks.useEntity();
  const { t } = useTranslation();
  const { question } = entity;
  const completed = hooks.useCompleted();
  const [openTestCaseDialog, setOpenTestCaseDialog] = React.useState(false);
  const [resetWorkspaceStatus, setResetWorkspaceStatus] = React.useState<"READY" | "PENDING" | "ERROR" | "SUCCEEDED">("READY");
  const { setWorkspace, setAgentServerHealth, setApplicationServerHealth } = ProjectCodingEditorV3.createProjectEntityAction(args.entityId);
  const { useWorkspace, useAgentServerHealth, useApplicationServerHealth } = ProjectCodingEditorV3.useCreateProjectEntityHooks(args.entityId);
  const workspace = useWorkspace();
  const agentServerHealth = useAgentServerHealth();
  const applicationServerHealth = useApplicationServerHealth();
  const [status, setStatus] = React.useState<"READY" | "RUNNING_TEST_CASE">("READY");
  const ipAddress = hooks.useIpAddress();
  const geolocation = hooks.useGeolocation();
  const initialFileIndexes = hooks.useInitialFileIndexes();
  const readOnlyFiles = hooks.useReadOnlyFilePatterns();

  const isResetWorkspaceLoading = resetWorkspaceStatus === "PENDING";

  const { pushEventHistory, pushAccessEvent } = useFirebaseSender({
    appType: "project",
    uid: args.uid,
    projectId: args.entityId,
    questionId: args.questionId,
  });
  const browserWindowEventDetector = React.useRef(
    new BrowserWindowEventDetect({
      callback: pushEventHistory,
    }),
  );

  React.useEffect(() => {
    if (!args.enableBrowserEventDetector) {
      return;
    }
    if (entity.projectStatus !== "STARTED") {
      return;
    }
    const stop = browserWindowEventDetector.current.subscribe();
    return () => {
      stop();
    };
  }, [entity.projectStatus, args.enableBrowserEventDetector, ipAddress, geolocation, pushAccessEvent]);

  React.useEffect(() => {
    const shouldDetectEvents = args.enableBrowserEventDetector && entity.projectStatus === "STARTED";

    if (!shouldDetectEvents) {
      return;
    }

    if (ipAddress !== null && geolocation !== null) {
      pushAccessEvent({
        l: ipAddress,
        g: geolocation,
        t: getTimestamp(),
      });
    }
  }, [ipAddress, geolocation, pushAccessEvent, args.enableBrowserEventDetector, entity.projectStatus]);
  const endpoints = useEndpoints({
    id: args.endpointId,
    mode: "DEVELOPMENT",
  });
  useAgentServerHealthCheck(args.entityId, endpoints.agentHealthCheck);
  React.useEffect(() => {
    if (agentServerHealth) {
      ScreeningTestTutorial.autoStartTutorial("PROJECT_V3");
    }
  }, [agentServerHealth]);
  useApplicationServerHealthCheck(args.entityId, endpoints.appHealthCheck, agentServerHealth);
  const appealMessageProps = useGenerateAppealMessageProps({
    mode: "DEVELOPMENT",
    appealMessage: entity.appealMessage ?? "",
    entityId: args.entityId,
  });
  const { isStartWorkspaceLoading } = useStartDevelopmentWorkspace({
    entityId: args.entityId,
    questionId: args.questionId,
    questionVersion: args.questionVersion,
    submissionId: args.submissionId,
  });
  const [state] = useProjectRealtimeDatabase({
    projectId: args.entityId,
    isInterviewing: !completed && !isStartWorkspaceLoading && !isResetWorkspaceLoading,
    isCandidate: args.isCandidate,
    selectedQuestion: args.questionId,
  });
  const fileTreeController = useFileTreeController({
    indexModel: args.indexModel,
    entityId: args.entityId,
    initialFileIndexes,
    readOnlyFilePatterns: readOnlyFiles,
    historiesPathsModel,
    fileSyncEndpoint: endpoints.fileSync,
    questionId: args.questionId,
    questionVersion: args.questionVersion,
  });

  const { focusedNode, setFocusNode } = useFocusNode(".", fileTreeController.fileTree);
  const projectNotReady = isStartWorkspaceLoading || isResetWorkspaceLoading || !state.ready || !fileTreeController.didConnectAgentServer;
  const editorValue = React.useMemo(() => {
    if (!fileTreeController.selectedFile) {
      return null;
    }
    return ProjectHelpers.findNode(fileTreeController.fileTree, fileTreeController.selectedFile)?.value ?? null;
  }, [fileTreeController.fileTree, fileTreeController.selectedFile]);

  const lspEndpoint = React.useMemo(() => {
    if (!projectNotReady && fileTreeController.didConnectAgentServer) {
      return endpoints.lsp;
    }
    return undefined;
  }, [endpoints.lsp, projectNotReady, fileTreeController.didConnectAgentServer]);

  /**
   * TODO: Check build script rather than question variant
   */
  const enableTerminal = question?.variant === "Backend" || question?.variant === "Frontend";

  const currentDirectoryFiles: string[] = React.useMemo((): string[] => {
    const node: ProjectHelpers.FileNode | null = ProjectHelpers.findNode(fileTreeController.fileTree, focusedNode);
    if (node?.isDir) {
      return Object.keys(node.children || {});
    }
    const parent = ProjectHelpers.findParent(fileTreeController.fileTree, focusedNode);
    return Object.keys(parent?.children || {});
  }, [focusedNode, fileTreeController.fileTree]);

  const terminalProps = useGenerateTerminalProps({
    ready: !projectNotReady && fileTreeController.didConnectAgentServer,
    endpoint: endpoints.build,
    runTestCaseButton: {
      disabled: completed || fileTreeController.connectedServerType !== "WEBSOCKET",
      loading: status === "RUNNING_TEST_CASE" || !agentServerHealth,
      onClick: () => {
        if (question?.variant === "Default") {
          Snackbar.notify({
            severity: "info",
            message: t("実行が開始しました。処理が終わるまでしばらくお待ち下さい。"),
          });
          setStatus("RUNNING_TEST_CASE");

          if (!workspace) {
            Snackbar.notify({
              severity: "error",
              message: t("実行に失敗しました。運営までお問い合わせください。"),
            });
            Sentry.captureException("failed to run project for project since workspace is not found");
            setOpenTestCaseDialog(true);
            setStatus("READY");
            return;
          }

          client
            .RunProjectForProjectCodingEditor({
              input: {
                projectId: args.entityId,
                questionId: args.questionId,
                questionVersion: args.questionVersion,
                testCase: question.defaultTestCase,
                workspaceId: workspace.id,
                takeSnapshot: true,
              },
            })
            .then(res => {
              action.setDefaultTestCaseState({
                status: "DEFAULT",
                snapshotStatus: res.runProject.status,
                failureReason: res.runProject.failureReason,
              });
              action.setTestCaseResultForDefault(res.runProject.testResult);
              Snackbar.notify({
                severity: "success",
                message: t("実行が完了しました。結果を確認してください。"),
              });
            })
            .catch(error => {
              Sentry.captureException(error);
              Snackbar.notify({
                severity: "error",
                message: t("実行に失敗しました。"),
              });
            })
            .finally(() => {
              setOpenTestCaseDialog(true);
              setStatus("READY");
            });
        } else {
          setOpenTestCaseDialog(true);
        }
      },
    },
    submitButton: {
      disabled: completed,
      loading: !agentServerHealth,
    },
    showConsole: enableTerminal,
  });

  const selectedNode = React.useMemo(() => {
    return findNode(fileTreeController.fileTree, fileTreeController.selectedFile ?? "");
  }, [fileTreeController.fileTree, fileTreeController.selectedFile]);

  const fileContentHistoryStoredPath = React.useMemo((): string | null => {
    if (!fileTreeController.selectedFile) {
      return null;
    }
    const fileId = historiesPathsModel.getLatestFileIdByFilepath(fileTreeController.selectedFile);
    if (!fileId) {
      return null;
    }
    return FirebaseStorePath.generateFileContentHistoryStoredPath({
      filepath: fileTreeController.selectedFile,
      fileId: fileId,
    });
  }, [historiesPathsModel, fileTreeController.selectedFile]);

  const extendedCodingEditorContainerProps = React.useMemo((): ExtendedCodingEditorContainerProps | undefined => {
    if (!fileContentHistoryStoredPath || !fileTreeController.selectedFile) {
      return;
    }

    const readOnly = selectedNode?.isReadOnly ?? false;

    return {
      uid: args.uid,
      value: editorValue ?? "",
      filepath: fileTreeController.selectedFile,
      uri: `file://${fileTreeController.cwd}/${fileTreeController.selectedFile}?w=${args.entityId}-${fileContentHistoryStoredPath}`,
      userName: args.userName,
      rdbKey: `projects/${args.entityId}/questions/${args.questionId}${fileContentHistoryStoredPath}`,
      readOnly: readOnly,
      lspServerUrl: lspEndpoint,
    };
  }, [
    fileContentHistoryStoredPath,
    fileTreeController.selectedFile,
    fileTreeController.cwd,
    selectedNode?.isReadOnly,
    args.uid,
    args.entityId,
    args.userName,
    args.questionId,
    editorValue,
    lspEndpoint,
  ]);

  const workspaceProps = React.useMemo((): Widget.ProjectCodingEditorV3Props["workspace"] => {
    if (!fileTreeController.selectedFile) {
      return {
        mode: "IDLE",
        idlePanel: {
          messages: [
            t("左側のファイルツリーから編集したいファイルを選択できます。"),
            t("エディタ下のターミナルには起動中のサーバーのログが表示されます。"),
          ],
        },
      };
    }
    const isNotebook = question?.variant === "DataScience" && fileTreeController.selectedFile?.match(/.ipynb$/);
    if (isNotebook) {
      if (fileTreeController.connectedServerType === "FIREBASE") {
        return {
          mode: "WAITING_CONNECT_SERVER",
        };
      }
      return {
        mode: "JUPYTER_NOTEBOOK",
        notebook: {
          filename: fileTreeController.selectedFile ?? "",
          config: {
            baseUrl: endpoints.httpBase,
            wsUrl: endpoints.wsBase,
            token: "",
          },
        },
      };
    }

    /**
     * 画像をmonaco-editorに食わせないようにする
     * base64などに変換して表示できるのであれば、ここを調整して表示する
     */
    const isImage = fileTreeController.selectedFile.match(IMAGE_EXTENSION_PATTERN);
    if (isImage) {
      return {
        mode: "IMAGE",
        src: fileTreeController.selectedFile,
      };
    }

    return {
      mode: "INJECTION",
      children: extendedCodingEditorContainerProps && (
        <ExtendedCodingEditorContainer
          key={`${args.entityId.toString()}-${extendedCodingEditorContainerProps.rdbKey}`}
          {...extendedCodingEditorContainerProps}
        />
      ),
    };
  }, [question?.variant, args.entityId, t, endpoints.httpBase, endpoints.wsBase, extendedCodingEditorContainerProps, fileTreeController]);

  const renderTreeProps: Widget.ProjectCodingEditorV3Props["fileNavigation"]["renderTree"] = {
    node: fileTreeController.fileTree,
    onSelectFile: sourceFile => {
      setFocusNode(sourceFile);
      fileTreeController.selectFile(sourceFile);
    },
    onSelectDirectory: directory => {
      setFocusNode(directory);
    },
  };

  const TestCaseComponent = React.useMemo((): React.ReactNode => {
    if (!question) {
      return null;
    }
    if (enabledProjectV4 && question.projectVersion === Graphql.ProjectVersion.V4) {
      /**
       * TODO 実装を行う
       */
      return <ProjectTestCaseRunnerContainer />;
    }
    switch (question.variant) {
      case "Backend": {
        const projectV3BackendTestCaseDialogContainerProps: ProjectV3BackendTestCaseDialogContainerProps = {
          open: openTestCaseDialog,
          onClose: () => {
            setOpenTestCaseDialog(false);
          },
          entityId: args.entityId,
          mode: "DEVELOPMENT",
        };
        return <ProjectV3BackendTestCaseDialogContainer {...projectV3BackendTestCaseDialogContainerProps} />;
      }
      case "DataScience": {
        const projectV3DataScienceTestCaseDialogContainerProps: ProjectV3DataScienceTestCaseDialogContainerProps = {
          open: openTestCaseDialog,
          onClose: () => {
            setOpenTestCaseDialog(false);
          },
          entityId: args.entityId,
          mode: "DEVELOPMENT",
        };
        return <ProjectV3DataScienceTestCaseDialogContainer {...projectV3DataScienceTestCaseDialogContainerProps} />;
      }
      case "Default": {
        const projectV3DefaultTestCaseDialogContainerProps: ProjectV3DefaultTestCaseDialogContainerProps = {
          open: openTestCaseDialog,
          onClose: () => {
            setOpenTestCaseDialog(false);
          },
          entityId: args.entityId,
          testCaseResult: hooks.useDefaultTestCaseResult(),
        };
        return <ProjectV3DefaultTestCaseDialogContainer {...projectV3DefaultTestCaseDialogContainerProps} />;
      }
      case "Frontend": {
        const projectV3FrontendTestCaseDialogContainerProps: ProjectV3FrontendTestCaseDialogContainerProps = {
          open: openTestCaseDialog,
          onClose: () => {
            setOpenTestCaseDialog(false);
          },
          entityId: args.entityId,
          mode: "DEVELOPMENT",
        };
        return <ProjectV3FrontendTestCaseDialogContainer {...projectV3FrontendTestCaseDialogContainerProps} />;
      }
      case "UNKNOWN":
        throw new Error(`Invalid project question: ${question.variant} variant`);
    }
  }, [enabledProjectV4, args.entityId, hooks, openTestCaseDialog, question]);

  return {
    enableTerminal: enableTerminal,
    disabledEditActionButtons: fileTreeController.connectedServerType === "WEBSOCKET" ? "EDITABLE" : "REMOTE_SERVER_NOT_RUNNING",
    sidebar: {
      questionSection: {
        title: resolveLanguage(question || {}, lang, "title"),
        description: resolveLanguage(question || {}, lang, "description"),
        /**
         * Always `false` as the user does not need to know if the issue has been archived during testing
         */
        isArchived: false,
      },
      appealMessage: appealMessageProps,
    },
    onChangeCreatingActionNode: targetNode => {
      setFocusNode(targetNode.id);
    },
    onCreateFile: (fields, targetNode) => {
      fileTreeController.addFile(targetNode.id, fields.value);
    },
    onCreateDirectory: (fields, targetNode) => {
      fileTreeController.addDir(targetNode.id, fields.value);
    },
    onDeleteNode: targetNode => {
      fileTreeController.remove(targetNode.id);
      if (focusedNode === targetNode.id) {
        setFocusNode(".");
      }
    },
    currentDirectoryFiles: currentDirectoryFiles,
    /**
     * 今戦うべき場所
     */
    fileNavigation: {
      focused: focusedNode,
      status: agentServerHealth ? "CONNECTED_SERVER" : "CONNECTING",
      fileTreeStatus: fileTreeController.fileTreeStatus,
      connectingPanel: {
        reconnectButton: agentServerHealth
          ? {
              onClick: controller => {
                controller.setLoading(true);
                fileTreeController.reconnect();
                window.setTimeout(() => {
                  controller.setLoading(false);
                }, 3000);
              },
            }
          : undefined,
      },
      resetButton: {
        disabled: completed || !agentServerHealth || resetWorkspaceStatus === "PENDING",
      },
      renderTree: renderTreeProps,
    },
    enableUiFrame: question?.variant === "Frontend",
    uiFrame:
      question?.variant === "Frontend"
        ? {
            url: endpoints.httpBase,
            loading: !applicationServerHealth || fileTreeController.connectedServerType !== "WEBSOCKET",
            openWindowButton: {
              onClick: () => {
                window.open(endpoints.httpBase, "_blank");
              },
            },
          }
        : undefined,
    toolbar: {
      fileTree: fileTreeController.fileTree,
      selectedFile: fileTreeController.selectedFile || undefined,
      openedFiles: fileTreeController.filesOpened,
      onSelectFile: fileTreeController.selectFile,
      onCloseFile: fileTreeController.closeFile,
    },
    workspace: workspaceProps,
    terminal: terminalProps,
    TestCaseComponent,
    submitConfirmDialog: {
      onAccept: controller => {
        controller.setLoading(true);
        client
          .EnqueueProjectSubmissionForProjectCodingEditor({
            input: {
              projectId: args.entityId,
              questionId: args.questionId,
              questionVersion: args.questionVersion,
            },
          })
          .then(() => {
            ProjectCodingEditorV3.setSubmittedEntity(entity);
            Snackbar.notify({
              severity: "success",
              message: t("お疲れ様でした！未提出の問題がある場合は次に進んでください。"),
            });
          })
          .catch(() => {
            Snackbar.notify({
              severity: "error",
              message: t("問題の提出に失敗しました。再度お試しください。"),
            });
          })
          .finally(() => {
            controller.setLoading(false);
          });
      },
    },
    resetConfirmDialog: {
      onAccept: (resetOption, controller) => {
        if (resetOption === null) return;

        setWorkspace(null);
        setAgentServerHealth(false);
        setApplicationServerHealth(false);

        controller.setLoading(true);
        switch (resetOption) {
          case "RESET_ALL": {
            fileTreeController.resetAllCodes();
            break;
          }
          case "RESET_ONLY_DEV_ENVIRONMENT": {
            fileTreeController.resetOnlyDevEnvironment();
            break;
          }
          default:
            throw new Error(`Reset option value is invalid: ${resetOption satisfies never}`);
        }
        /**
         * TODO @Himenon ソースコードを引き継がない場合（withSourceCode === false）の場合、
         * 初期コードを取得して、HistoriesPathsModelを一度すべてDELETEして、再initializeする
         */
        client
          .ResetDevelopmentWorkspaceForProjectCodingEditor({
            input: {
              projectId: args.entityId,
              /**
               * true  : ソースコードも含めてリセットする
               * false : ソースコードはそのままで環境だけリセットする
               */
              withSourceCode: resetOption === "RESET_ALL",
            },
          })
          .then(res => {
            if (res) {
              setResetWorkspaceStatus("SUCCEEDED");
              setWorkspace(res.resetDevelopmentWorkspace);
            }
          })
          .catch(error => {
            setResetWorkspaceStatus("ERROR");
            Sentry.captureException(error);
          })
          .finally(() => {
            controller.setLoading(false);
          });
      },
    },
  };
};
