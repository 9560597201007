import { Widget } from "@hireroo/presentation";
import { ErrorBoundary, withErrorBoundary } from "@sentry/react";
import * as React from "react";

import { type GenerateProjectV3DefaultTestCaseDialogPropsArgs, useGenerateProps } from "./useGenerateProps";

export type ProjectV3DefaultTestCaseDialogContainerProps = GenerateProjectV3DefaultTestCaseDialogPropsArgs;

const ProjectV3DefaultTestCaseDialogContainer: React.FC<ProjectV3DefaultTestCaseDialogContainerProps> = props => {
  const projectV3DefaultTestCaseDialogProps = useGenerateProps(props);
  return (
    <ErrorBoundary>
      <Widget.ProjectV3DefaultTestCaseDialog {...projectV3DefaultTestCaseDialogProps} />
    </ErrorBoundary>
  );
};

ProjectV3DefaultTestCaseDialogContainer.displayName = "ProjectV3DefaultTestCaseDialogContainer";

export default withErrorBoundary(ProjectV3DefaultTestCaseDialogContainer, {});
