import { ProjectTestReport } from "@hireroo/app-store/view-domain/ProjectTestReport";
import { Widgets } from "@hireroo/evaluation-result-ui";
import * as Sentry from "@sentry/react";

export type GenerateEvaluationTestResultPropsArgs = {
  entityId: number;
};

export const useGenerateProps = (args: GenerateEvaluationTestResultPropsArgs): Widgets.TestResultProps => {
  const hooks = ProjectTestReport.useCreateProjectHooks(args.entityId);
  const submission = hooks.useCurrentSubmission();

  return {
    rawData: submission?.testResult || "",
    onError: error => {
      console.error(error, submission?.testResult);
      Sentry.captureException(error);
    },
  };
};
