import { ShowingTarget } from "@hireroo/app-helper/entity";
import { SystemDesignTestReport } from "@hireroo/app-store/view-domain/SystemDesignTestReport";
import { formatSeconds } from "@hireroo/formatter/time";
import { useLanguageCode, useTranslation } from "@hireroo/i18n";
import type { Widget } from "@hireroo/presentation";
import * as React from "react";

import { useGenerateSystemDesignScoringItemPropsFactory } from "../../../../props-factory/v2/view-domain/SystemDesignTestReport/privateHelper";
import { useGenerateEntityScoreBoardProps } from "../../../../props-factory/v2/view-domain/SystemDesignTestReport/useGenerateEntityScoreBoardProps";
import { useGenerateHeaderProps } from "../../../../props-factory/v2/view-domain/SystemDesignTestReport/useGenerateHeaderProps";
import { useGenerateQuestionDetailProps } from "../../../../props-factory/v2/view-domain/SystemDesignTestReport/useGenerateQuestionDetailProps";
import SystemDesignPlaybackContainer from "../../../../props-factory/v2/view-domain/SystemDesignTestReport/widget/SystemDesignPlayback/Container";
import TotalScoreRankVisualizerInitialContainer from "../../shared/TotalScoreRankVisualizer/InitialContainer";

export type GenerateSystemDesignTestReportPropsArgs = {
  showingTargets: ShowingTarget[];
  entityId: number;
  companyId: number;
  uniqueKey: SystemDesignTestReport.UniqueKey;
};

export const useGenerateProps = (args: GenerateSystemDesignTestReportPropsArgs): Widget.SystemDesignTestReportProps => {
  const lang = useLanguageCode();
  const { t } = useTranslation();
  const hooks = SystemDesignTestReport.useCreateSystemDesignHooks(args.entityId);
  const currentSubmission = hooks.useCurrentSubmission();
  const aggregatedScoringItemMap = hooks.useAggregatedScoringItemMap();
  const numOfRun = hooks.useNumOfRun();
  const numOfUsedHint = hooks.useNumOfUsedHint();
  const statistics = hooks.useStatistics();
  const appealMessage = hooks.useAppealMessage();
  const convert = useGenerateSystemDesignScoringItemPropsFactory({
    entityId: args.entityId,
    showAnswer: args.showingTargets.includes("ANSWER"),
  });
  const rankEvaluation = hooks.useRankEvaluation();
  const [mode, setMode] = React.useState<"PLAYBACK" | "SUBMIT_RESULT">("SUBMIT_RESULT");
  const header = useGenerateHeaderProps({ entityId: args.entityId });
  const aggScoringItemMap = hooks.useCalculatedScoringItemMap();
  const scoreBoard = useGenerateEntityScoreBoardProps({
    entityId: args.entityId,
    showingTargets: [
      args.showingTargets.includes("SCORE") && ("SCORE" as const),
      args.showingTargets.includes("RANK") && ("RANK" as const),
    ].flatMap(v => (v ? [v] : [])),
  });
  const questionDetail = useGenerateQuestionDetailProps({
    entityId: args.entityId,
    showAnswer: args.showingTargets.includes("ANSWER"),
    showArchivedMark: false,
  });

  return {
    header: header,
    scoreBoard: scoreBoard,
    StatisticsContent: args.showingTargets.includes("STATISTICS") && (
      <TotalScoreRankVisualizerInitialContainer
        uniqueKey={args.uniqueKey}
        score={currentSubmission?.totalScore ?? 0}
        rankEvaluation={rankEvaluation}
        enableRank
      />
    ),
    playbackSection: {
      playbackModeSwitcher: args.showingTargets.includes("PLAYBACK")
        ? {
            enableMode: ["SUBMIT_RESULT", "PLAYBACK"],
            onChangeMode: mode => {
              if (mode === "PLAYBACK" || mode === "SUBMIT_RESULT") {
                setMode(mode);
              }
            },
          }
        : undefined,
      ContentsViewer: <SystemDesignPlaybackContainer entityId={args.entityId} mode={mode} />,
      appealMessageArea: !!appealMessage && {
        body: appealMessage,
      },
    },
    answerDetailSection: {
      totalElapsedTime: {
        value: formatSeconds(currentSubmission?.totalElapsedTimeSeconds ?? 0, lang),
        icon: {
          key: (currentSubmission?.totalElapsedTimeSeconds ?? 0) <= statistics.elapsedAvgTimeSeconds ? "CHECK" : "WARNING",
          title: `${t("平均解答時間")} ${formatSeconds(statistics.elapsedAvgTimeSeconds, lang)}`,
        },
      },
      runNum: {
        value: `${numOfRun} ${t("回")}`,
        icon: {
          key: numOfRun <= statistics.numOfRunAvg ? "CHECK" : "WARNING",
          title: `${t("平均実行回数")} ${statistics.numOfRunAvg}`,
        },
      },
      usedHint: {
        value: `${numOfUsedHint} ${t("個")}`,
        icon: {
          key: numOfUsedHint <= statistics.numOfHintAvg ? "CHECK" : "WARNING",
          title: `${t("平均使用ヒント数")} ${statistics.numOfHintAvg}`,
        },
      },
      canShowTooltip: args.showingTargets.includes("STATISTICS"),
    },
    editableScoreSection: {
      onSubmit: () => undefined,
      defaultValues: {
        scoringItems: aggregatedScoringItemMap.all.items.map(item => {
          return {
            id: item.systemDesignScoringItemId,
            isPassed: item.isPassed,
          };
        }),
      },
      availability: {
        scrollTargetId: SystemDesignTestReport.TargetElementIdMap.AVAILABILITY_SECTION,
        titleWithScoreBar: {
          title: t("可用性"),
          score: args.showingTargets.includes("SCORE") ? aggScoringItemMap.availability?.score ?? 0 : undefined,
          color: "secondary",
        },
        items: aggregatedScoringItemMap.availability.items.map(convert),
      },
      scalability: {
        scrollTargetId: SystemDesignTestReport.TargetElementIdMap.SCALABILITY_SECTION,
        titleWithScoreBar: {
          title: t("スケーラビリティ"),
          score: args.showingTargets.includes("SCORE") ? aggScoringItemMap.availability?.score ?? 0 : undefined,
          color: "info",
        },
        items: aggregatedScoringItemMap.scalability.items.map(convert),
      },
      consistency: {
        scrollTargetId: SystemDesignTestReport.TargetElementIdMap.CONSISTENCY_SECTION,
        titleWithScoreBar: {
          title: t("一貫性"),
          score: args.showingTargets.includes("SCORE") ? aggScoringItemMap.availability?.score ?? 0 : undefined,
          color: "warning",
        },
        items: aggregatedScoringItemMap.consistency.items.map(convert),
      },
    },
    questionDetail: questionDetail,
  };
};
